import React, { Component, ReactNode } from 'react';
import { css } from 'glamor';

/*
DESC:
    a section in which the container width is set to 100vw and 
    the height is user-defined. 

*/

export default class SectionFS extends Component<{
    h?: number;
    bg?: string;
    children?: any;
}> {
    state = {};

    render() {
        return (
            <div
                {...css({
                    height: this.props.h ? this.props.h : 'auto',
                    width: '100%',
                    backgroundColor: this.props.bg
                        ? this.props.bg
                        : 'traspararent'
                })}
            >
                {this.props.children}
            </div>
        );
    }
}
