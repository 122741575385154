
import * as React from "react"
import { ClientFooter } from "../../client-ui/ClientFooter";
import { LayoutFS, on } from "../../locust/composables/Layout";
import { PreloadScreen } from "../../locust/composables/PreloadScreen";
import Rsp from "../../locust/composables/Rsp";
import HeaderRsp from "../../locust/responsive/HeaderRsp";
import Bg from '../../assets/images/landing/sales-svc-bg-1280.png'
import BgII from '../../assets/images/landing/SalesSvc-background-pic-1280-new.png'
import BgIIMobile from '../../assets/images/landing/sales-svc-bg-mobile-new.png'




import Winwing from '../../assets/images/landing/sales-svc-winwing-pto-1280.svg'
import Buttkicker from '../../assets/images/landing/sales-svc-buttkicker-1280.svg'
import TripleSet from '../../assets/images/landing/sales-svc-image-set-thrustmaster-hotas-1280.svg'
import DoubleSet from '../../assets/images/landing/sales-svc-image-set-headset-and-flight-seat.svg'
import F15 from "../../assets/images/landing/f-15c-eagle-1280.svg"

import { css } from "glamor";
import {Stack} from "../../locust/composables/Stack";
import SpacerV from "../../locust/sections/SpacerV";
import { getFontLato } from "../../assets/fonts/FontLato";
import { Link } from "gatsby";
import XY from "../../locust/composables/XY";
import X from "../../locust/composables/X";
import { getChildren } from "../../locust/composables/Shared";
import { getFontPoppins } from "../../assets/fonts/FontPoppins";
import Y from "../../locust/composables/Y";
import { BulletinIconAndTitle, IconFooter } from "../tarmac";
import { SectionRsp } from "..";
import {Hide2} from "../../locust/composables/Hide";
import Scale from "../../locust/composables/Scale";
import { ScaleOn } from "../flight-ops";
import SalesServiceLogoOnly from '../../assets/images/landing/sales-and-service-icon-only.svg'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Carousel1 from '../../assets/images/landing/SalesSvc-Carousel1-300px.png'
import Carousel2 from '../../assets/images/landing/SalesSvc-Carousel2-300px.png'
import Carousel3 from '../../assets/images/landing/SalesSvc-Carousel3-300px.png'
import Carousel4 from '../../assets/images/landing/SalesSvc-Carousel4-300px.png'

import {BulletinIconAndTitleRsp as BulletinIconAndTitleRspII} from "../tarmac/index";

const ContentText = {
    MAIN_TEXT : [
        <>Flight Line VR offers complete simulation rigs for sale.</>,
        <>Our Rigs feature premium flight peripherals, high-speed computer systems, and haptic transducers that generate an amazingly realistic, immersive experience. Pilot interviews, detailed research, and technical evaluations guided our design iterations and device integration decisions. There is no better choice or better priced option for your at-home, hi-fidelity 'Sim Rig.'</>,
        <>We are an authorized reseller for many PC peripherals including Thrustmaster, Logitech, Samsung, HP, NVIDIA, and more.</>,
        <>Contact us for complete build options and pricing.</>
    ]
}


const ContentLeft = () => {
    return (
        <>
            <SpacerV h={10}/>
            <X>
            <MainText>
                {ContentText.MAIN_TEXT}
                <SpacerV h={10}/>
            </MainText>
            </X>
            
            <SpacerV h={10}/>
            <X>
                <TrippleItem icon={TripleSet}>Thrustmaster Warthog HOTAS (Throttle deck, stick, rudder pedals)</TrippleItem>
                <DoubleItem>
                    <SingleItem icon={Winwing}>Winwing PTO Panel</SingleItem>
                    <SingleItem icon={Buttkicker}>Buttkicker Plus Haptic Transducer</SingleItem>
                </DoubleItem>
            </X>
            
            

        </>
    )
}

export const onBreak : string = on(670)

const ContentLeftMobile = () => <ContentLeftMobileV2/>

const ContentLeftMobileV1 = () => {

  const Bullet = ( props : {children ?: any }) => <p {...css({ 
    fontSize : 16,
    lineHeight : 1.1,
    [onBreak] : {
      fontSize : 14,
    }

  })}>{props.children}</p>

  return (
      <>
          <SpacerV h={20}/>
          <X>
          <MainText>
              {ContentText.MAIN_TEXT}
              <SpacerV h={10}/>
          </MainText>
          </X>
          {/*
          <SpacerV h={10}/>
          <X style={{ height : 300, marginLeft : 70 }}>
              <Hide2 on={670}>
                  <BulletinIconAndTitle titleStyle={{ width : 300, marginLeft : 150 }} style={{ marginLeft : -280 }} title="Typical Components" icon={F15} w={400}>
                      <MainText style={{ width : 400}}>
                          <Bullet>Flight Sim Gaming Computer</Bullet>
                          <Bullet>Flight Sim Operating System SW</Bullet>
                          <Bullet>HP Reverb G2(TM) with attached earphones</Bullet>
                          <Bullet>F-GT Flight Simulator Cockpit Seat</Bullet>
                          <Bullet>Thrustmaster Warthog(TM) HOTAS</Bullet>
                          <Bullet>WINWING PTO Panel</Bullet>
                          <Bullet>Leap Motion Controller(TM) Hand Tracker</Bullet>
                          <Bullet>ELGATO LCD StreamDeck(TM)</Bullet>
                          <Bullet>BUTTKICKER GAMER PLUS(TM)</Bullet>
                          <Bullet>Standard Keyboard and Mouse</Bullet>
                      </MainText>
                  </BulletinIconAndTitle>
              </Hide2>
              <Hide2 b4={670}>
                  <BulletinIconAndTitle titleStyle={{ width : 300, marginLeft : 160 }} style={{ marginLeft : -280 }} title="Typical Components" w={400}>
                      <MainText style={{ width : 400, marginLeft : 138}}>
                          <Bullet>Flight Sim Gaming Computer</Bullet>
                          <Bullet>Flight Sim Operating System SW</Bullet>
                          <Bullet>HP Reverb G2(TM) with attached earphones</Bullet>
                          <Bullet>F-GT Flight Simulator Cockpit Seat</Bullet>
                          <Bullet>Thrustmaster Warthog(TM) HOTAS</Bullet>
                          <Bullet>WINWING PTO Panel</Bullet>
                          <Bullet>Leap Motion Controller(TM) Hand Tracker</Bullet>
                          <Bullet>ELGATO LCD StreamDeck(TM)</Bullet>
                          <Bullet>BUTTKICKER GAMER PLUS(TM)</Bullet>
                          <Bullet>Standard Keyboard and Mouse</Bullet>
                      </MainText>
                  </BulletinIconAndTitle>
              </Hide2>
          </X>
          */}
        

      </>
  )
}


const ContentLeftMobileV2 = () => {


  

  return (
      <>
          
        
          <div {...css({
              marginLeft : -20,
              [on(1280)]:{
                marginLeft : 70
              }
          })}>
                          
              <BulletinIconAndTitleRspII>
                  {ContentText.MAIN_TEXT}
              </BulletinIconAndTitleRspII>
          </div>
            
          
      
      </>
  )
}



const ContentRight = () => {
  return (
      <>
          
          <X style={{ height : 300 }}>
            <BulletinSmall title="Typical Components">
                <>Flight Sim Gaming Computer</>
                <>Flight Sim Operating System SW</>
                <>HP Reverb G2(TM) with attached earphones</>
                <>F-GT Flight Simulator Cockpit Seat</>
                <>Thrustmaster Warthog(TM) HOTAS</>
                <>WINWING PTO Panel</>
                <>Leap Motion Controller(TM) Hand Tracker</>
                <>ELGATO LCD StreamDeck(TM)</>
                <>BUTTKICKER GAMER PLUS(TM)</>
                <>Standard Keyboard and Mouse</>
            </BulletinSmall>
          </X>
          <SpacerV h={20}/>
          <X>
              <CustomItem icon={DoubleSet}>
                  <>HP Reverb G2 Headset</>
                  <>F-GT Flight Simulator Cockpit Seat</>
              </CustomItem>
          </X>
      </>
  )
}



const ContentRightMobile = () => {
  return (
      <>
          <SpacerV h={20}/>
          <ScaleOn scale={0.8} on={670} styleOn={{ marginLeft : 50 }}>
              <TrippleItem icon={TripleSet} style={{ marginLeft : -50}}>
                  Thrustmaster Warthog HOTAS (Throttle deck, stick, rudder pedals)
              </TrippleItem>
          </ScaleOn>
          
          <ScaleOn scale={0.8} on={670} styleOn={{ marginLeft : 0, marginBottom : -30 }} style={{ marginBottom : 30 }}>
              <DoubleItem>
                  <SingleItem icon={Winwing}>Winwing PTO Panel</SingleItem>
                  <SingleItem icon={Buttkicker}>Buttkicker Plus Haptic Transducer</SingleItem>
              </DoubleItem>
          </ScaleOn>

          <ScaleOn scale={0.8} on={670} styleOn={{ marginLeft : 50, marginBottom : -50 }}>
              <CustomItem icon={DoubleSet}>
                  <>HP Reverb G2 Headset</>
                  <>F-GT Flight Simulator Cockpit Seat</>
              </CustomItem>
          </ScaleOn>

          
      </>
  )
}



const ContainerLeft = ( props : { children ?: any, c?: string  }) => {
    return (
          <div style={{ width : '65%', height : 'auto', verticalAlign : 'top',backgroundColor : props.c ? props.c : '', display : 'inline-block'}}>
            {props.children}
        </div>
        
    )
}

const ContainerRight = ( props : { children ?: any, c ?: string }) => {
  return (
    <div style={{ width : '35%', height : 'auto', verticalAlign : 'top',backgroundColor : props.c ? props.c : '', display : 'inline-block'}}>
          {props.children}
      </div>
      
  )
}

const Page = () => {
  const [toggle, setToggle] = React.useState(false)
  
  return <>
      <PreloadScreen>
          <LayoutFS min={350}>
          <HeaderRsp breakpoint={750} menu={{ setToggle : setToggle, toggle : toggle }}/>
                <SpacerV h={50}/>
                <div style={{ position : 'absolute', left : 'calc(50vw - 90px)'}}>
                    <Y>
                        <img src={SalesServiceLogoOnly} width={100} height={100}/>
                        <SpacerV h={10}/>
                        <p style={{ ...getFontPoppins(), fontWeight : 900, fontSize : 30, textTransform : 'uppercase'  }}>Sales & Service</p>
                        <SpacerV h={10}/>
                        <p style={{ ...getFontPoppins(), position : 'absolute', top : 180, marginLeft : 8, width : '100vw', backgroundColor : '', textAlign : 'center', fontWeight : 300, marginTop : -12, fontSize : 16 }}>Purchase Your Own Simulator. Plug-n-play architecture.</p>
                    </Y>
                </div>
                <SpacerV h={220}/>
              <Stack>

                    
                  <SectionRsp>
                    <div {...css({
                          background: `url(${BgII})`,
                          backgroundRepeat : 'no-repeat',
                          backgroundColor : 'white',
                          width : '100%',
                          height : '100%',
                          [ on(660) ]:{
                              background: `url(${BgIIMobile})`,
                              backgroundSize : '100%',
                              backgroundPosition : 'center',
                              backgroundRepeat : 'no-repeat',
                          }
                    })}/>

                  </SectionRsp>


                  <SectionRsp>
                      <SpacerV h={0}/>
                      <div style={{ width : 'calc(100% - 70px)', height : 'auto', backgroundColor : ''}}>
                          {/*
                          <Hide2 on={1280}>
                              <ContainerLeft>
                                  <ContentLeft/>
                              </ContainerLeft> 
                              

                              <ContainerRight>
                                <ContentRight/>
                              </ContainerRight>
                              
                          </Hide2>

                          <Hide2 b4={1280}>
                              <ContentLeftMobile/>
                          </Hide2>
                          */}

                          <ContentLeftMobile/>
                          <SpacerV h={20}/>
                          <div style={{ maxWidth : 500, marginLeft : 'calc(50% - 250px)' }}>
                          <CarouselRsp  
                          >   
                              {/* style={{ borderRadius : 10, width : 300*1.1, height : 270*1.1}} */}
                              
                                {Carousel1}
                                {Carousel2}
                                {Carousel3}
                                {Carousel4}
                              
                              
                              

                              {/*
                              <X>
                                <img style={{ borderRadius : 10, width : 300*1.1, height : 270*1.1}}src={Carousel1}/>
                              </X>
                              <X>
                                <img style={{ borderRadius : 10, width : 300*1.1, height : 270*1.1}}src={Carousel2}/>
                              </X>
                              <X>
                                <img style={{ borderRadius : 10, width : 300*1.1, height : 270*1.1}}src={Carousel3}/>
                              </X>
                              <X>
                                <img style={{ borderRadius : 10, width : 300*1.1, height : 270*1.1}}src={Carousel4}/>
                              </X>
                              */}

                          </CarouselRsp>  
                          </div>
                          
                      </div>
   
                  </SectionRsp>
              </Stack>
              <Hide2 b4={1280}>
                  <Stack>
                      {/*
                          <SectionRsp style={{ height : 'auto' }}>
                              <ContentRightMobile/>
                          </SectionRsp>
                      */}

                      
                  </Stack>
                  
              </Hide2>
              <X>
                  
                  <FooterText>
                    {/*
                      Actual models of the components in the purchased SIMPIT may vary from what is depicted or listed herein. Any substitution will be clearly specified along with associated impact to pricing as part of the inital quote.
                    */}
                    Technical support and troubleshooting included with all orders.
                  </FooterText>
                  
              </X>
              <SpacerV h={20}/>
              <IconFooter icon={F15} n={3} size={30}/>
              <SpacerV h={50}/>
              <ClientFooter/>
          </LayoutFS>
      </PreloadScreen>
      
  </>
}

const CarouselStyled = ( props : { children ?: any }) => {
  return (
      <>
      <Carousel  
              autoPlay={true}
              focusOnSelect={false}
              draggable={false}
              swipeable={false}
              keyBoardControl={false}
              responsive={{
                  min: {
                      breakpoint: { max: 10000, min: 0 },
                      items: 1
                  }
              }}
              infinite={true}
          >   
              {props.children}
              
              
          </Carousel> 
      </>
  )
}

const CarouselRsp = ( props : { children ?: any }) => {
  const scale1 : number = 1.54
  const scale2 : number = 1.1
  const scale3 : number = 1.27
  const children : any[] = getChildren(props.children)
    return(
        <div style={{ marginLeft : 10 }}>
            <Hide2 on={1280}>
                <CarouselStyled>   
                    {children.map(( child : any )=>{
                        return (
                            <X>
                                <img style={{ borderRadius : 10, width : 300*scale1, height : 270*scale1}}src={child}/>
                            </X>
                        )
                    })}
                </CarouselStyled> 
            </Hide2>
            <Hide2 b4={1280} on={670}>
                <CarouselStyled>   
                    {children.map(( child : any )=>{
                        return (
                            <X>
                                <img style={{ borderRadius : 10, width : 300*scale2, height : 270*scale2}}src={child}/>
                            </X>
                        )
                    })}
                </CarouselStyled> 
            </Hide2>
            <Hide2 b4={670}>
                <CarouselStyled>   
                    {children.map(( child : any )=>{
                        return (
                            <X>
                                <img style={{ borderRadius : 10, width : 300*scale3, height : 270*scale3}}src={child}/>
                            </X>
                        )
                    })}
                </CarouselStyled> 
            </Hide2>
        </div>
    )
}
const DoubleItem = ( props : { children ?: any, icon ?: any, style ?: Record<string,any>  }) => {
  return (
      <div {...css({ 
          verticalAlign : 'top',
          display : 'inline-block',
          width : 200, 
          height : 360, 
          ...props.style
      })}>
            
            <Y>
              {props.children}
            </Y>
      </div>
  )
}

const SingleItem = ( props : { children ?: any, icon ?: any, style ?: Record<string,any>  }) => {
  return (
      <div {...css({ 
          verticalAlign : 'top',
          display : 'inline-block',
          width : 200, 
          height : 180, 
          //backgroundColor : 'whitesmoke',
          fontSize : 16,
          fontWeight : 800,
          textTransform : 'uppercase' ,
          color : 'black',
          ...getFontLato()
      })}>
            
            <XY style={{ marginTop : 0, ...props.style }}>
              <img width="160px" height="160px" src={props.icon}/>
            </XY>
            <X style={{ height : 10 , marginTop : -20, textAlign : 'center' }}>
              {props.children}
            </X>
      </div>
  )
}


const TrippleItem = ( props : { children ?: any, icon ?: any, style ?: Record<string,any>  }) => {
  return (
      <div {...css({ 
          verticalAlign : 'top',
          display : 'inline-block',
          width : 200*2, 
          height : 180*2, 
          //backgroundColor : 'whitesmoke',
          fontSize : 16,
          fontWeight : 800,
          textTransform : 'uppercase' ,
          color : 'black',
          ...getFontLato()
      })}>
            
            <XY style={{ marginTop : 0, ...props.style }}>
              <img width="320px" height="320px" src={props.icon}/>
            </XY>
            <X style={{ height : 10 , marginTop : -20, textAlign : 'center' }}>
              {props.children}
            </X>
      </div>
  )
}


const CustomItem = ( props : { children ?: any, icon ?: any, style ?: Record<string,any>  }) => {
  const children : any = getChildren(props.children)
  return (
      <div {...css({ 
          verticalAlign : 'top',
          display : 'inline-block',
          width : 200*2, 
          height : 180*2, 
          //backgroundColor : 'whitesmoke',
          fontSize : 16,
          fontWeight : 800,
          textTransform : 'uppercase' ,
          color : 'black',
          ...getFontLato()
      })}>
            
            <XY style={{ marginTop : 0, ...props.style }}>
              <img width="320px" height="320px" src={props.icon}/>
            </XY>
            <X style={{ height : 10 , marginTop : -20, textAlign : 'center' }}>
              {children.length > 1 && children[1] }
            </X>
            {children.length > 0 && 
              <div style={{ marginTop : -200, marginLeft : 20, position : 'absolute'}}>{children[0]}</div>
            }
            
      </div>
  )
}


const BulletinSmall = ( props : { children ?: any, title ?: string }) => {
    const children : any = getChildren(props.children)
    return (
        <div {...css({ 
          display : 'inline-block',
          //backgroundColor : 'white', 
          padding: 20, 
          width : 380, 
          height : 'auto',
          borderRadius : 10,
          })}>
          <div {...css({
            ...getFontPoppins(),
            fontSize : 18,
            textTransform : 'uppercase' ,
            paddingBottom : 0,
          })}>{props.title}</div>

          {children.map(( child : any, index : number )=>{ return <p key={index} {...css({
              ...getFontPoppins(),
              fontSize : 16,
              color : 'black',
          })}>
          
            - {child}
          
          
          </p> })}

        </div>
    )
}



export const MainText = ( props : { children ?: any, footer ?: string, title ?: string, style ?: Record<string,any> }) => {
  const children : any = getChildren(props.children)
  return (
      <div {...css({ 
        display : 'inline-block',
        backgroundColor : 'rgba(255,255,255,0.6)', 
        paddingLeft: 20, 
        paddingRight: 20,
        marginLeft : 10, 
        width : 600, 
        height : 'auto',
        borderRadius : 10,
        [onBreak] : {
          width : 300, 
        },...props.style,
        })}>
     

        {children.map(( child : any, index : number )=>{ return <p key={index} {...css({
            ...getFontPoppins(),
            fontSize : 20,
            color : 'black',
            [onBreak] : {
              fontSize : 14,
            }
        })}>
          <SpacerV h={10}/>
          {child}
        </p> })}

        { props.footer && 
            <>
                <SpacerV h={10}/>
                <div {...css({
                  ...getFontPoppins(),
                  fontSize : 16,
                  textAlign : 'center',
                  paddingBottom : 0,
                })}>{props.footer}</div>
                <SpacerV h={10}/>
            </>
        }


      </div>
  )
}



const FooterText = ( props : { children ?: any }) => {
  return (
      <div {...css({ 
        display : 'inline-block',
        backgroundColor : 'white', 
        padding: 20, 
        width : 800, 
        height : 'auto',
        borderRadius : 10,
        })}>
     
        <div {...css({
          ...getFontPoppins(),
          fontSize : 14,
          textAlign : 'center',
          paddingBottom : 0,
        })}>{props.children}</div>


      </div>
  )
}


const Airplane = ( props : { children ?: any, icon ?: any, style ?: Record<string,any>  }) => {
    return (
        <div {...css({ 
            verticalAlign : 'top',
            display : 'inline-block',
            width : 200, 
            height : 180, 
            //backgroundColor : 'whitesmoke',
            fontSize : 16,
            fontWeight : 800,
            textTransform : 'uppercase' ,
            color : 'black',
            ...getFontLato()
        })}>
              <X style={{ height : 10 }}>
                {props.children}
              </X>
              <XY style={{ marginTop : -20, ...props.style }}>
                <img width="160px" height="160px" src={props.icon}/>
              </XY>
        </div>
    )
}

export default Page;