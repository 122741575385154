
import * as React from "react"
import { ClientFooter } from "../../client-ui/ClientFooter";
import { LayoutFS, on } from "../../locust/composables/Layout";
import { PreloadScreen } from "../../locust/composables/PreloadScreen";

import HeaderRsp from "../../locust/responsive/HeaderRsp";
import Bg from '../../assets/images/landing/flight-ops-background-1280.png'
import Ah64 from '../../assets/images/landing/ah-64-apache-1280.svg'
import A10c from '../../assets/images/landing/a-10c-thunderbolt-ii-1280.svg'

import Av8b from "../../assets/images/landing/av-8b-harrier-II-1280.svg"
import F5e from "../../assets/images/landing/f-5e-tiger-1280.svg"

import F14 from "../../assets/images/landing/f-14a-tomcat-1280.svg"
import F15 from "../../assets/images/landing/f-15c-eagle-1280.svg"
import F16 from "../../assets/images/landing/f-16c-falcon-1280.svg"
import F18c from "../../assets/images/landing/f-18c-hornet-1280.svg"

import P15 from "../../assets/images/landing/p-15d-mustang-1280.svg"
import Huey from "../../assets/images/landing/uh-1-huey-1280.svg"


import { css } from "glamor";
import { Stack } from "../../locust/composables/Stack";
import SpacerV from "../../locust/sections/SpacerV";
import { getFontLato } from "../../assets/fonts/FontLato";
import { Link } from "gatsby";
import XY from "../../locust/composables/XY";
import X from "../../locust/composables/X";
import { getChildren } from "../../locust/composables/Shared";
import { getFontPoppins } from "../../assets/fonts/FontPoppins";
import { BulletinIconAndTitle, IconFooter, TitleTextBg } from "../tarmac";
import { onBreak, SectionRsp } from "..";
import { Hide2 } from "../../locust/composables/Hide";
import { MainText } from "../sales-and-service";
import Scale from "../../locust/composables/Scale";
import Y from "../../locust/composables/Y";
import BgB from '../../assets/images/landing/flt-ops-background-ii.svg'
import BgMobile from '../../assets/images/landing/flight-ops-bg-mobile.png'


import FlightOpsLogoOnly from '../../assets/images/landing/flight-ops-icon-only.svg'

import {BulletinIconAndTitleRsp as BulletinIconAndTitleRspII} from "../tarmac/index";


const SCALE = 0.8

const TextContent = {
    SIMULATORS : [
        <>We offer an incredible flight experience!</>,
        <>Our state-of-the-art simulators give you an opportunity to immerse yourself in the world of a tactical fighter/attack pilot or helo driver.</>,
        <>Realistic flight dynamics require that you manage your energy, keep an eye on your fuel state, and watch out for G-LOC.</>
    ]
}

const ContentLeftDesktop = () => {
    return (
        <>
            <SpacerV h={20}/>
            <X>

                <BulletinIconAndTitle title="Simulators"  w={400} style={{ marginLeft : -80}}>
                    <MainText style={{ width : 400}}>
                        
                        <>{ TextContent.SIMULATORS.map(( item : any, index : number  )=>{
                        return <p style={{ fontSize : 16 , marginTop : index === 0 ? 0 : 14 }}>{item}</p>
                        })}</>
                        <SpacerV h={4}/>
                    </MainText>
                </BulletinIconAndTitle>

                
            </X>
            <SpacerV h={20}/>
            <X>
                <Airplane invert style={{ marginTop : 0 }} icon={Ah64}>AH-64 Apache</Airplane>
                <Airplane invert icon={A10c} style={{ marginTop : 0 }}>A-10C Thunderbolt II</Airplane>
                <Airplane invert icon={Av8b} style={{ marginTop : 0 }}>AV-8B HARRIER II</Airplane>
            </X>
            <X>
                <Airplane invert icon={F5e} style={{ marginTop : 0 }}>F-5E Tiger</Airplane>
                <Airplane invert icon={Huey} style={{ marginTop : 0 }}>UH-1 Huey</Airplane>
            </X>
        </>
    )
}

 



export const BulletinIconAndTitleRsp = ( props : { title?: string, children : any }) => {
    const Bullet = ( props : { children ?: any }) => <p {...css({
        fontSize : 16,
        [onBreak] : {
            fontSize : 13
        }
    })}>{props.children}</p>
    const scale : number = 0.9

    const children = getChildren(props.children)
    return (
        <>
            <Hide2 on={450}>
                    <BulletinIconAndTitle title={props.title}  w={400}>
                        <MainText style={{ width : 400, [onBreak] : {
                            marginLeft : 5
                        }}}>
                            { children.map(( value : any )=>{
                                return <Bullet>{value}</Bullet>
                            })}

                            {/*
                            <Bullet>State-of-the-art VR headsets</Bullet>
                            <Bullet>High-end Dedicated Computers</Bullet>
                            <Bullet>Amazing Thrustmaster Warthog Peripherals</Bullet>
                            <Bullet>Haptic Feedback Transducers</Bullet>
                            */}
                            
                        </MainText>
                    </BulletinIconAndTitle>
                </Hide2>
                <Hide2 b4={450}>
                    <BulletinIconAndTitle title="Simulators"  w={300}>
                        <MainText style={{ width : 300, [onBreak] : {
                            marginLeft : 5
                        }}}>

                            { children.map(( value : any )=>{
                                return <Bullet>{value}</Bullet>
                            })}
                            {/*
                            <Bullet>State-of-the-art VR headsets</Bullet>
                            <Bullet>High-end Dedicated Computers</Bullet>
                            <Bullet>Amazing Thrustmaster Warthog Peripherals</Bullet>
                            <Bullet>Haptic Feedback Transducers</Bullet>
                            */}
                            
                        </MainText>
                    </BulletinIconAndTitle>
                </Hide2>
        </>
    )
}



export const ScaleOn = ( props : { children ?: any, scale : number, on : number, style ?: Record<string,any>, styleOn ?: Record<string,any> }) => {
    const children = getChildren(props.children)
    return (
        <>
             <Hide2 on={props.on}>
                <X style={{ marginLeft : -60 }}>

                    { children.map(( child : any, index : number )=>{
                        return <div {...css(props.style)} key={index}>{ child}</div>
                    }) }
                    {/*
                        <Airplane icon={Ah64}>AH-64 Apache</Airplane>
                        <Airplane icon={A10c} style={{ marginTop : 0 }}>A-10C Thunderbolt II</Airplane>
                    */}
                </X>
            </Hide2>
            <Hide2 b4={props.on}>
                <X style={{ marginLeft : -20 }}>
                    { children.map(( child : any, index : number )=>{
                        return <div {...css(props.styleOn)} key={index}><Scale scale={props.scale}>{ child}</Scale></div>
                    }) }
                    {/*
                        <Scale scale={props.scale}><Airplane icon={Ah64}>AH-64 Apache</Airplane></Scale>
                        <Scale scale={props.scale}><Airplane icon={A10c} style={{ marginTop : 0 }}>A-10C Thunderbolt II</Airplane></Scale>
                    */}
                </X>
            </Hide2>
        </>
    )
}

const ContentLeftMobile = () => <ContentLeftMobileV3/>

const ContentLeftMobileV2 = () => {
    
    return (
        <>
            <SpacerV h={40}/>
            <X style={{ height : 220, marginLeft : -100 }}>
                <BulletinIconAndTitleRsp title="Simulators">
                    <>{ TextContent.SIMULATORS.map(( item : any, index : number  )=>{
                        return <p style={{ marginTop : index === 0 ? 0 : 14 }}>{item}</p>
                    })}</>
                    <SpacerV h={4}/>
                </BulletinIconAndTitleRsp>
            </X>
  
            <SpacerV h={80}/>
            <X style={{ height : 220, marginLeft : -100 }}>
                <BulletinIconAndTitleRsp title="Aircraft">
                    <>Fixed Wing, Variable Geometry, Rotor</>
                    <>High Fidelity Computer Modules</>
                    <>Interactive Cockpit Instrumentation</>
                    <>Realistic Flight Dynamics</>
                    <SpacerV h={4}/>
                </BulletinIconAndTitleRsp>
            </X>
            
            
        </>
    )
  }


  const ContentLeftMobileV3 = () => {
    
    return (
        <>
            <SpacerV h={40}/>
            
                <BulletinIconAndTitleRspII title="Simulators">
                    <>{ TextContent.SIMULATORS.map(( item : any, index : number  )=>{
                        return <p style={{ marginTop : index === 0 ? 0 : 14 }}>{item}</p>
                    })}</>
                    <SpacerV h={4}/>
                </BulletinIconAndTitleRspII>

                
            
  
            <SpacerV h={80}/>
            
                <BulletinIconAndTitleRspII title="Aircraft">
                    <>Fixed Wing, Variable Geometry, Rotor</>
                    <>High Fidelity Computer Modules</>
                    <>Interactive Cockpit Instrumentation</>
                    <>Realistic Flight Dynamics</>
                    <SpacerV h={4}/>
                </BulletinIconAndTitleRspII>
            
            
            
        </>
    )
  }


  
const ContentLeftMobileV1 = () => {
    
  return (
        <>
            <X style={{ height : 220, marginLeft : -100 }}>
                <BulletinIconAndTitleRsp title="Simulators">
                    <>{TextContent.SIMULATORS}</>
                    <SpacerV h={4}/>
                </BulletinIconAndTitleRsp>
            </X>

            
          
            <ScaleOn on={450} scale={SCALE}>
                <Airplane invert icon={Ah64}>AH-64 Apache</Airplane>
                <Airplane invert icon={A10c} style={{ marginTop : 10 }}>A-10C Thunderbolt II</Airplane>
            </ScaleOn>
        
            <ScaleOn on={450} scale={SCALE}>
                <Airplane invert icon={Av8b}>AV-8B HARRIER II</Airplane>
                <Airplane invert icon={F5e}>F-5E Tiger</Airplane>
            </ScaleOn>
            
            <ScaleOn on={450} scale={SCALE}>
                <Airplane invert icon={Huey} style={{ marginTop :-10 }} textStyle={{  marginTop : -40 }}>UH-1 Huey</Airplane>
            </ScaleOn>
          
          
      </>
  )
}

const ContentRightDesktop = () => {
  return (
      <>  
            <SpacerV h={20}/>
            <X style={{ height : 220 }}>

            
                <BulletinIconAndTitle title="Aircraft"  w={400} style={{ marginLeft : -50 }}>
                    <MainText style={{ width : 400}}>
                        <p style={{ fontSize : 16 }}>Fixed Wing, Variable Geometry, Rotor</p>
                        <p style={{ fontSize : 16 }}>High Fidelity Computer Modules</p>
                        <p style={{ fontSize : 16 }}>Interactive Cockpit Instrumentation</p>
                        <p style={{ fontSize : 16 }}>Realistic Flight Dynamics</p>
                        <SpacerV h={4}/>
                    </MainText>
                </BulletinIconAndTitle>
              
            </X>

            <SpacerV h={70}/>
            <X style={{ height : 200 }}>
                <Airplane invert icon={F14} style={{ marginTop : 0 }}>F-14A Tomcat</Airplane>
                <Airplane invert icon={F15} style={{ marginTop : 0 }}>F-15C Eagle</Airplane>
                <Airplane invert icon={F16} style={{ marginTop : 0 }}>F-16C Falcon</Airplane>
            </X>
            <X style={{ height : 200 }}>
            
                <Airplane invert icon={F18c} style={{ marginTop : 0 }}>F-18c Hornet</Airplane>
                <Airplane invert icon={P15} style={{ marginTop : 0 }}>P-51D Mustang</Airplane>
                
            </X>
          
      </>
  )
}


const ContentRightMobile = () => <ContentRightMobileV2/>
const ContentRightMobileV1 = () => {
  return (
        <>
        <IconFooter icon={F15} n={3} size={30}/>
        
        <SpacerV h={50}/>

        <Stack>    
                        
            <SectionRsp>
                <div style={{
                    background: `url(${Bg})`,
                    backgroundRepeat : 'no-repeat',
                    backgroundColor : 'white',
                    width : '100%',
                    height : '100%',
                }}></div>
            </SectionRsp>
            
            <SectionRsp>
                
                

                <SpacerV h={0}/>
                <X style={{ height : 220, marginLeft : -110 }}>
                    <BulletinIconAndTitleRsp title="Aircraft">
                        <>Fixed Wing, Variable Geometry, Rotor</>
                        <>High Fidelity Computer Modules</>
                        <>Interactive Cockpit Instrumentation</>
                        <>Realistic Flight Dynamics</>
                        <SpacerV h={4}/>
                    </BulletinIconAndTitleRsp>
                </X>

                <SpacerV h={20}/>

                <ScaleOn on={450} scale={SCALE}>
                    <Airplane invert icon={F14} style={{ marginTop : 0 }}>F-14A Tomcat</Airplane>
                    <Airplane invert icon={F15} style={{ marginTop : 0 }}>F-15C Eagle</Airplane>
                </ScaleOn>
                <ScaleOn on={450} scale={SCALE}>
                    <Airplane invert icon={F16} style={{ marginTop : 0 }}>F-16C Falcon</Airplane>
                    <Airplane invert icon={F18c} style={{ marginTop : 0 }}>F-18c Hornet</Airplane>
                </ScaleOn>
                <ScaleOn on={450} scale={SCALE}>
                    <Scale scale={0.8}>
                        <Airplane invert icon={P15} textStyle={{marginTop: 5, marginLeft : 0, fontSize : 20 }} style={{ marginTop : 10, marginLeft : 70, width : 260  }}>P-51D Mustang</Airplane>
                    </Scale>
                </ScaleOn>
            </SectionRsp>
            </Stack>
      </>
  )
}

const ContentRightMobileV2 = () => {
    const h : number = 1100
    return (
        <>  
               
                      
             
              
            <SectionRsp style={{ height : h}}>

                    <X style={{ height : 40, marginLeft : -50 }}>
                        <TitleTextBg width={'300px'}>Choose Your Ride</TitleTextBg>
                    </X>
                    <SpacerV h={50}/>
                    
                    
                    <div {...css({
                        background: `url(${BgB})`,
                        backgroundRepeat : 'no-repeat',
                        
                        backgroundPosition : 'center top',
                        //backgroundColor : 'black',
                        marginLeft : 'calc(50% - 300px)',
                        width : 600,
                        height : h
                    })}>
                        
                        <ScaleOn on={450} scale={SCALE}>
                            <Airplane invert icon={Ah64}>AH-64 Apache</Airplane>
                            <Airplane invert icon={A10c} style={{ marginTop : 10 }}>A-10C Thunderbolt II</Airplane>
                        </ScaleOn>
                        <ScaleOn on={450} scale={SCALE}>
                            <Airplane invert icon={Av8b} style={{ marginTop : 0 }}>AV-8B HARRIER II</Airplane>            
                            <Airplane invert icon={F5e} style={{ marginTop : 0 }}>F-5E Tiger</Airplane>
                        </ScaleOn>
                        <ScaleOn on={450} scale={SCALE}>
                            <Airplane invert icon={F14} style={{ marginTop : 0 }}>F-14A Tomcat</Airplane>
                            <Airplane invert icon={F15} style={{ marginTop : 0 }}>F-15C Eagle</Airplane>
                        </ScaleOn>
                        <ScaleOn on={450} scale={SCALE}>
                            <Airplane invert icon={F16} style={{ marginTop : 0 }}>F-16C Falcon</Airplane>
                            <Airplane invert icon={F18c} style={{ marginTop : 0 }}>F-18c Hornet</Airplane>
                        </ScaleOn>
                        <ScaleOn on={450} scale={SCALE}>

                            <ScaleOn on={1280} scale={0.8}>
                                <Airplane invert icon={P15} textStyle={{marginTop: 5, marginLeft : 30 }} style={{ marginTop : 10, marginLeft : 50, width : 260, fontSize : 20  }}>P-51D Mustang</Airplane>
                            </ScaleOn>
                            
                            <Airplane invert icon={Huey} style={{ marginTop :-10, marginLeft : -50 }} textStyle={{  marginLeft : -40, marginTop : -15 }}>UH-1 Huey</Airplane>
                            
                        </ScaleOn>

                        {/*
                        <X style={{ backgroundColor : 'rgba(255,255,255,0.7)', paddingTop : 50 }}>
                            <IconFooter style={{ marginLeft : -40 }} icon={F15} n={3} size={30}/>
                        </X>
                        <SpacerV h={50}/>
                        */}                        
                        
                    </div>

                </SectionRsp>

                
            
              
        </>
    )
  }

const Page = () => {
  const [toggle, setToggle] = React.useState(false)
  const link : Record<string,any> = css({ display : 'block',textDecoration : 'none', [':hover']:{ textDecoration : 'underline' },[':visited']:{ color : 'white'}})
  return <>
      <PreloadScreen>
          <LayoutFS min={350}>
                <HeaderRsp breakpoint={750} menu={{ setToggle : setToggle, toggle : toggle }}/>
                <SpacerV h={50}/>
                <div style={{ position : 'absolute', left : 'calc(50vw - 90px)'}}>
                    <Y>
                        <img src={FlightOpsLogoOnly} width={100} height={100}/>
                        <SpacerV h={10}/>
                        <p style={{ ...getFontPoppins(), fontWeight : 900, fontSize : 30, textTransform : 'uppercase'  }}>Flight Ops</p>
                        <SpacerV h={10}/>
                        <p style={{ ...getFontPoppins(), fontWeight : 300, marginTop : -12, fontSize : 16 }}>What We Offer</p>
                    </Y>
                </div>
                <SpacerV h={200}/>
              <Stack>
                  
              <SectionRsp>
                  
                <div {...css({
                    background: `url(${Bg})`,
                    backgroundRepeat : 'no-repeat',
                    backgroundPosition : '-10px 0px',
                    backgroundColor : 'white',
                    marginLeft : -20,
                    width : '100%',
                    height : '100%',
                    [ on(660) ]:{
                        background: `url(${BgMobile})`,
                        backgroundSize : '100%',
                        backgroundPosition : 'center',
                        backgroundRepeat : 'no-repeat',
                    }
                })}></div>
                

              </SectionRsp>
                    

                  <SectionRsp>
                      <SpacerV h={0}/>
                      <div style={{ width : 'calc(100% - 10px)', height : 'auto', backgroundColor : ''}}>
                          <Hide2 on={1280}>
                              <div style={{ display : 'inline-block',width : '50%', verticalAlign : 'top', height : 'auto', backgroundColor : ''}}>
                                  <ContentLeftDesktop/>

                              </div>
                              <div style={{ display : 'inline-block',width : '50%', verticalAlign : 'top', height : 'auto', backgroundColor : ''}}>
                                  <ContentRightDesktop/>
                                  

                              </div>
                              
                          </Hide2>
                          <Hide2 b4={1280}>
                              <ContentLeftMobile/>
                          </Hide2>
                    

                      </div>
                      
                      
                     
                      
                  </SectionRsp>
                  </Stack>
                  <Hide2 b4={1280}>
                      <SpacerV h={30}/>
                      
                      
                          
                            <ContentRightMobile/>
                        
                  </Hide2>

                <Hide2 on={1280}>
                    <X style={{ backgroundColor : 'rgba(255,255,255,0.7)', paddingTop : 50 }}>
                        <IconFooter style={{ marginLeft : -40 }} icon={F15} n={3} size={30}/>
                    </X>
                    <SpacerV h={50}/>
                </Hide2>
                  
              <ClientFooter/>
          </LayoutFS>
      </PreloadScreen>
      
  </>
}

const Bulletin = ( props : { children ?: any, title ?: string }) => {
    const children : any = getChildren(props.children)
    return (
        <div {...css({ 
          display : 'inline-block',
          backgroundColor : 'white', 
          padding: 20, 
          width : 500, 
          height : 'auto',
          borderRadius : 10,
          })}>
          <div {...css({
            ...getFontPoppins(),
            fontSize : 26,
            textTransform : 'uppercase' ,
            paddingBottom : 10,
            marginLeft : 80,
          })}>{props.title}</div>

          {children.map(( child : any, index : number )=>{ return <p key={index} {...css({
              ...getFontPoppins(),
              fontSize : 20,
              fontWeight : 500,
              color : 'black',
          })}>
          
            - {child}
          
          
          </p> })}

        </div>
    )
}
const Airplane = ( props : { children ?: any, invert ?: boolean, icon ?: any, style ?: Record<string,any>, textStyle ?: Record<string,any>  }) => {
    
    const text = <X {...css({ height : 10 })}><div {...css({marginTop : -10, ...props.textStyle})}>{props.children}</div></X>
    const img = <XY {...css({         
    })}>
        <div {...css({...props.style})}>
            <img width="160px" height="160px" src={props.icon}/>
        </div>
    </XY>
    return (
        <div {...css({ 
            verticalAlign : 'top',
            display : 'inline-block',
            width : 200, 
            height : 180, 
            marginTop : 10, 
            //backgroundColor : 'whitesmoke',
            fontSize : 16,
            fontWeight : 800,
            textTransform : 'uppercase' ,
            color : 'black',
            ...getFontLato(),
            ...props.style

        })}>
                { !props.invert && 
                    <>
                        {text}
                        {img}
                    </>
                }

                { props.invert && 
                    <>
                        {img}
                        {text}
                    </>
                }

              
        </div>
    )
}

export default Page;