
import * as React from "react"
import { ClientFooter } from "../../client-ui/ClientFooter";
import { LayoutFS, on } from "../../locust/composables/Layout";
import { PreloadScreen } from "../../locust/composables/PreloadScreen";
import Rsp from "../../locust/composables/Rsp";
import HeaderRsp from "../../locust/responsive/HeaderRsp";
import Bg from '../../assets/images/landing/tarmac-bg-1280.png'
import BgMobile from '../../assets/images/landing/tarmac-bg-mobile.png'

import Winwing from '../../assets/images/landing/sales-svc-winwing-pto-1280.svg'
import Buttkicker from '../../assets/images/landing/sales-svc-buttkicker-1280.svg'
import TripleSet from '../../assets/images/landing/sales-svc-image-set-thrustmaster-hotas-1280.svg'
import DoubleSet from '../../assets/images/landing/sales-svc-image-set-headset-and-flight-seat.svg'

import F15 from "../../assets/images/landing/f-15c-eagle-1280.svg"

import { css } from "glamor";
import { Stack } from "../../locust/composables/Stack";
import SpacerV from "../../locust/sections/SpacerV";
import { getFontLato } from "../../assets/fonts/FontLato";
import { Link } from "gatsby";
import XY from "../../locust/composables/XY";
import X from "../../locust/composables/X";
import { getChildren, getEmpty } from "../../locust/composables/Shared";
import { getFontPoppins } from "../../assets/fonts/FontPoppins";
import Y from "../../locust/composables/Y";
import { onBreak, SectionRsp } from "..";
import { FACEBOOK_BLACK } from "../../assets/colors/Colors";
import { MainText } from "../sales-and-service";
import {Hide2} from '../../locust/composables/Hide'

import TarmacLogoOnly from '../../assets/images/landing/tarmac-icon-only.svg'


const ContentLeft = ( props : { icon ?: any, titleStyle?: Record<string,any>, style ?: Record<string,any>}) => {

    const Bullet = ( props : {children ?: any }) => <p {...css({
        fontSize : 16,
        [onBreak] : {
            fontSize : 14,
        }

    })}>{props.children}</p>
    
                        
    return (
        <div {...css(props.style)}>
            <BulletinIconAndTitle title="Pricing" titleStyle={props.titleStyle} icon={props.icon} w={400}>
                <MainText style={{ width : 400}}>
                    <Bullet>
                        PARTY TIME: Starting at $150. Duration varies. Book out the entire store. Available for up to 6 active players and 10 participants. 1.5 or 2.5 hour time blocks (time to include set-up and break-down).
                    </Bullet>
                    <SpacerV h={4}/>
                </MainText>
            </BulletinIconAndTitle>
            <SpacerV h={6}/>
            <BulletinIconAndTitle icon={props.icon} w={400}>
                <MainText style={{ width : 400}}>
                    <Bullet>
                        FLIGHT SIM: Starting at $20 · 25 minutes and up. We offer flight sim experiences from the curious to the curated.
                    </Bullet>
                    <SpacerV h={4}/>
                </MainText>
            </BulletinIconAndTitle>
            <SpacerV h={6}/>
            <BulletinIconAndTitle icon={props.icon} w={400}>
                <MainText style={{ width : 400}}>
                    <Bullet>
                        ROOM-SCALE VR: Starting at $10 · 25 minutes and up. Come and enjoy the wireless experience of Virtual Reality as you’ve come to know it. Advances have been made so no longer is a long cord needed to connect to the digital world.
                    </Bullet>
                    <SpacerV h={4}/>
                </MainText>
            </BulletinIconAndTitle>
        </div>
    )
}

const ContentRight = ( props : { style ?: Record<string,any>, titleStyle ?: Record<string,any>, icon ?: any  }) => {
    const Bullet = ( props : {children ?: any, style ?: Record<string,any>}) => <p {...css({
        fontSize : 16,
        [onBreak] : {
            fontSize : 14,
            lineHeight : 1.4
        },
        ...props.style

    })}>{props.children}</p>
    return (
        <div {...css(props.style)}>
        
            <BulletinIconAndTitle title="Operating Hours" titleStyle={props.titleStyle} icon={props.icon} w={400}>
                <MainText style={{ width : 400}}>
                    <Bullet>M: 9:00 am – 7:00 pm</Bullet>
                    <Bullet>Tu-Th: 9:00 am – 7:00 pm (Appt. Only)</Bullet>
                    <Bullet>F-S: 10:00am – 8:00pm; Sunday: Closed</Bullet>
                    <SpacerV h={4}/>
                </MainText>
            </BulletinIconAndTitle>
            <SpacerV h={10}/>
            <BulletinIconAndTitle title="Merchandise" titleStyle={props.titleStyle} icon={props.icon} w={400}>
                <MainText style={{ width : 400}}>
                    <Bullet>
                        How about some cool merch? Visit our store for T-shirts, hoodies, & more. Come back often for new stuff! Coming Soon.
                    </Bullet>
                    <SpacerV h={4}/>
                </MainText>
            </BulletinIconAndTitle>
            <SpacerV h={10}/>
            <BulletinIconAndTitle title="Contact Us" titleStyle={props.titleStyle} icon={props.icon} w={400}>
                <MainText style={{ width : 400}}>
                    <Bullet>
                        Sales/Svc/Reservations: (208) 918-3427
                    </Bullet>
                    <Bullet>
                        Email: michael@durant.tech
                    </Bullet>
                    <Bullet>
                        Address: 290 North Main St
                        </Bullet>
                    <Bullet style={{ marginTop : -14 }}>
                        Mountain Home, ID 83647
                    </Bullet>
                    <SpacerV h={4}/>
                </MainText>
            </BulletinIconAndTitle>
        
        </div>
    )
}

const SectionValues = {
    TOP : {
        text : `We focus on bringing the most advanced Combat Flight Simulator available to the public. Prior to the start of your paid session, you will receive a free 15 minute, pre-flight tutorial that teaches you enough of the basic flight mechanics for a great time. We have a variety of aircraft modules that give you a great choice for your flying experience.`
    }
}

const ContentDesktop = () => {
    return (
        <>
          <Hide2 on={1280}>
            <SpacerV h={10}/>
            <div style={{ verticalAlign : 'top' }}>
                
                
                <div style={{ display : 'inline-block', width : '50%', verticalAlign : 'top'}}>
                    <ContentLeft icon={F15} style={{ marginLeft : 50 }}/>
                </div>

                
                <div style={{ display : 'inline-block', width : '50%', verticalAlign : 'top'}}>
                    <ContentRight icon={F15} style={{ marginLeft : 25 }}/>
                </div>
                    
                    

            </div>
            </Hide2>      
            
        </>
    )
}


const ContentLeftMobile = () => {
  return (
      <>
        <Hide2 b4={1280}>
            {/*
          <SpacerV h={20}/>
          <X>
              <BulletinIconAndTitle title="About Us" w={500} titleStyle={{ marginLeft : 100 }} style={{ marginLeft : -300 }}>
                    <MainText style={{ 
                        width : 600, 
                        textAlign : 'center', 
                        marginLeft : 50,
                        [onBreak] : {
                            marginLeft : 175,
                            width : 350, 
                        }
                }}> 
                      {SectionValues.TOP.text}
                      <SpacerV h={4}/>
                  </MainText>
              </BulletinIconAndTitle>
          </X>
          */}
          <SpacerV h={10}/>
          <div style={{ verticalAlign : 'top' }}>
              
                <Hide2 on={670}>
                    <div style={{ marginLeft : 'calc(50% - 320px)' }}>
                        <ContentLeft icon={F15}/>
                    </div>
                </Hide2>
                  
                <Hide2 b4={670}>
                    <div style={{ marginLeft : 'calc(50% - 210px)' }}>
                        <ContentLeft titleStyle={{ marginLeft : -40 }}/>
                    </div>
                </Hide2>


              {/*
              <div style={{ display : 'inline-block', width : '50%', verticalAlign : 'top'}}>
                  
              </div>


              <div style={{ display : 'inline-block', width : '50%', verticalAlign : 'top'}}>
                  <ContentRight/>
              </div>
              */}

          </div>
          </Hide2>      
      </>
  )
}


const ContentRightMobile = () => {
  return (
      <>
          <Hide2 b4={1280}>
              <SpacerV h={50}/>
              <IconFooter icon={F15} n={3} size={30}/>
              <SpacerV h={50}/>
              <Stack>
                  <SectionRsp>
                    <div style={{
                        background: `url(${Bg})`,
                        backgroundRepeat : 'no-repeat',
                        backgroundColor : 'white',
                        width : '100%',
                        height : '100%',
                        [on(670)]:{
                            background: `url(${BgMobile})`,
                            backgroundSize : '100%',
                            backgroundPosition : 'center',
                            backgroundRepeat : 'no-repeat',
                        }
                      }}></div>
                  </SectionRsp>
                  
                  <SectionRsp>
                      <SpacerV h={20}/>
                      <div style={{ verticalAlign : 'top' }}>
                        <Hide2 on={670}>
                            <div style={{ marginLeft : 'calc(50% - 320px)' }}>
                                 <ContentRight icon={F15}/>
                            </div>
                        </Hide2>
                        <Hide2 b4={670}>
                            <div style={{ marginLeft : 'calc(50% - 210px)' }}>
                                 <ContentRight titleStyle={{ marginLeft : -40 }}/>
                            </div>
                        </Hide2>
                      </div>
                  </SectionRsp>
                  
              </Stack>
          </Hide2>

      </>
  )
}

const Page = () => {
  const [toggle, setToggle] = React.useState(false)
  const link : Record<string,any> = css({ display : 'block',textDecoration : 'none', [':hover']:{ textDecoration : 'underline' },[':visited']:{ color : 'white'}})
  return <>
      <PreloadScreen>
          <LayoutFS min={350}>
                <HeaderRsp breakpoint={750} menu={{ setToggle : setToggle, toggle : toggle }}/>
                
                <SpacerV h={70}/>
                <div style={{ position : 'absolute', left : 'calc(50vw - 65px)'}}>
                    <Y>
                        <img src={TarmacLogoOnly} width={100} height={100}/>
                        <SpacerV h={10}/>
                        <p style={{ ...getFontPoppins(), fontWeight : 900, fontSize : 30, textTransform : 'uppercase'  }}>Tarmac</p>
                        <SpacerV h={10}/>
                      <p style={{ ...getFontPoppins(), fontWeight : 300, marginTop : -12, fontSize : 16, textAlign : 'center' }}>General Info</p>
                    </Y>
                </div>
                <SpacerV h={200}/>
              
              <Stack>
                  <SectionRsp>
                    <div {...css({
                        background: `url(${Bg})`,
                        backgroundRepeat : 'no-repeat',
                        backgroundColor : 'white',
                        marginLeft : -10,
                        width : '100%',
                        height : '100%',
                    })}/>
                  </SectionRsp>
                  <SectionRsp>
                        <BulletinIconAndTitleRsp title="About Us">
                                {SectionValues.TOP.text}
                        </BulletinIconAndTitleRsp>
                      <ContentDesktop/>
                      <ContentLeftMobile/>
                  </SectionRsp>
              </Stack>
              <ContentRightMobile/>
              
              <SpacerV h={50}/>
              <IconFooter icon={F15} n={3} size={30}/>
              <SpacerV h={50}/>
            <ClientFooter/>
          </LayoutFS>
      </PreloadScreen>
      
  </>
}




export const BulletinIconAndTitleRsp = ( props : { title ?: string, children ?: any  }) => {
    return (
        <>
            <Hide2 on={1280}>
                <SpacerV h={20}/>
                <BulletinIconAndTitle title={props.title} w={1280} titleStyle={{ marginLeft : -55}}>
                    <MainText style={{ width : 1100, textAlign : 'center', marginLeft : 50,  }}> 
                        {props.children}
                        <SpacerV h={10}/>
                    </MainText>
                </BulletinIconAndTitle>

            </Hide2>
            <Hide2 b4={1280}>
                <SpacerV h={20}/>
                <X>
                    <BulletinIconAndTitle 
                        title={props.title} 
                        w={500} 
                        titleStyle={{ marginLeft : 100 }} 
                        style={{ marginLeft : -300 }}
                    >
                        
                        <MainText style={{ 
                            width : 600, 
                            textAlign : 'center', 
                            marginLeft : 50,
                            [onBreak] : {
                                marginLeft : 175,
                                width : 350, 
                            }
                        }}> 
                            {props.children}
                            
                            <SpacerV h={4}/>

                        </MainText>
                    </BulletinIconAndTitle>
                </X>
            </Hide2>
        </>
    )
}


            


export const BulletinIconAndTitle = ( props : { children ?: any, w?: number, title ?: string, icon ?: string, style ?: Record<string,any>, titleStyle ?: Record<string,any> }) => {
    const left : number = props.icon ? 70 : 0
    const top : number = props.title ? 50 : 0
    const margin : number = 20 
    const width : number = props.w ? props.w : 500 
    const titleWidth : string = props.titleStyle && props.titleStyle.width ? props.titleStyle.width : undefined
    return (
        <div style={{ width : width, height : 'auto', backgroundColor : '', verticalAlign : 'top', ...props.style}}>
            { props.title && 
                <div style={{ width : width + left + left + margin + margin , height : top, backgroundColor : '', ...props.titleStyle }}>
                        <X>
                            <TitleTextBg width={titleWidth}>{props.title}</TitleTextBg>
                        </X>
                    
                </div>
            }

            <div style={{ display : 'flex', flexDirection: 'row', width : left, height : `calc(100% - 50px)`, verticalAlign : 'top', backgroundColor : ''}}>

                <div style={{  flex : 1, backgroundColor : '', alignItems:'center', justifyContent : 'center'}}>
                    <Y>
                        <IconFooter icon={F15} n={1}/>
                    </Y>
                </div>

                <div style={{ width : `calc(100% - ${left}px)`, height : `calc(100% - ${top}px)`, display : 'inline-block', backgroundColor : ''}}>
                    {props.children}
                </div>
            </div>

            

        </div>
    )
}

export const TitleTextBg = ( props : { children ?: any, width ?: string }) => {
    return (
        <>
          <div style={{
                     
                     

                     
                     //backgroundColor : 'black',
                     width : props.width ? props.width : 220,
                     height : 40,
                     borderRadius : 8,
                     backgroundImage : `radial-gradient(${FACEBOOK_BLACK},rgba(0,0,0,0.7))`
                     
    
                     //boxShadow: '0 0 8px 8px white inset'
                  }}>
                    <XY>
                    <p style={{
                      fontSize : 20,
                      //fontWeight : 700,
                      textTransform : 'uppercase' ,
                      color : 'white',
                      ...getFontLato()
                    }}> {props.children}</p>
                    </XY>
                  </div>
        </> 
    )
}

export const IconFooter = ( props : { icon : string, children ?: any, size ?: number, n?: number, gap ?: number, style ?: Record<string,any> }) => {
    const size : number =  props.size ? props.size : 60
    const gap : number = props.gap ? props.gap : 50
    const n : number = props.n ? props.n : 3
    return (
        <>
            <X style={{ height : size, ...props.style }}>
                { getEmpty(n).map(( value : any, index : number )=>{
                    const isGap : boolean = index < n - 1
                    return (
                      <img style={{ marginRight : isGap ? gap : 0 }} width={`${size}px`} height={`${size}px`} src={props.icon}/>
                    )
                })}
            </X>
        </>
    )
}



export default Page;