import React from "react"
import Footer from "../locust/components/Footer"
import { Facebook } from '@styled-icons/boxicons-logos/Facebook';
import { InstagramAlt } from '@styled-icons/boxicons-logos/InstagramAlt';
import { Linkedin } from '@styled-icons/entypo-social/Linkedin';
import { Twitter } from '@styled-icons/entypo-social/Twitter';
import { css } from "glamor";
import { getConfigDefaults } from "../locust/composables/Layout";

export const ClientFooter = () => {
    return (
        
        <Footer 
            siteMap={[
                { url : '/', value : 'Home'},
                { url : '/flight-ops', value : 'Flight Ops'},
                { url : '/sales-and-service', value : 'Sales & Service'},
                { url : '/tarmac', value : 'Tarmac'},
                { url : 'https://www.printify.com/flightlinevr', value : 'Store'},
            ]}
            contacts={[
                //{ url : 'tel:208-515-9425', value : 'Call Us' },
                { url : 'mailto:info@flightlinevr.com', value : 'Email Us' }
            ]}
            social={[
                {
                    value: 'Facebook',
                    icon: <Facebook width={28} />,
                    url: 'https://facebook.com/flightlinevr/'
                },
                
                {
                    value: 'Instagram',
                    icon: <InstagramAlt width={18} />,
                    url: 'https://www.instagram.com/flight_line_vr/'
                },
                /* 
                {
                    value: 'LinkedIn',
                    icon: <Linkedin width={16} />,
                    url: 'https://www.linkedin.com'
                },
                {
                    value : 'Twitter',
                    icon: <Twitter width={16} />,
                    url: 'https://www.linkedin.com'
                }
                */
            ]}
        />
        
    )
}
