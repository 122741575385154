import React, { Component, ReactNode } from 'react';
import { GlobalConfig, onMedia, validateConfig } from '../composables/Layout';
import { ifProp, Ref } from '../composables/Shared';
import LCRToOne from './LCRToOne';
import SectionFS from './SectionFS';

export interface SectionLCRToCTemplate {
    bg?: string;
    left: Ref;
    center: Ref;
    right: Ref;
    wrapper: Ref;
    customBreakpoint?: number;
}

export const validateSLCRCT = (template?: SectionLCRToCTemplate) => {
    if (template) return template;

    const empty: SectionLCRToCTemplate = {
        left: {},
        center: {},
        right: {},
        wrapper: {}
    };

    return empty;
};

export default class SectionLCRToC extends Component<{
    globals?: GlobalConfig;
    right?: ReactNode | string;
    left?: ReactNode | string;
    center?: ReactNode | string;
    customBreakpoint: number;
    template?: SectionLCRToCTemplate;
}> {
    render() {
        const customBreakpoint = ifProp(this.props.customBreakpoint, 600);
        const onCustomBreakpoint: string = onMedia(customBreakpoint);
        const globals: GlobalConfig = validateConfig(this.props.globals);
        const onMobile: string = globals.onMedia(globals.MOBILE);
        const Template: SectionLCRToCTemplate = validateSLCRCT(
            this.props.template
        );
        return (
            <>
                <SectionFS bg={Template.bg ? Template.bg : 'white'}>
                    <LCRToOne
                        breakpoint={
                            Template.customBreakpoint
                                ? Template.customBreakpoint
                                : customBreakpoint
                        }
                        left={<>{this.props.left}</>}
                        center={<>{this.props.center}</>}
                        right={<>{this.props.right}</>}
                        template={{
                            wrapper: {
                                overflow: 'hidden',
                                ...Template.wrapper
                            },
                            left: {
                                [onMobile]: {
                                    width: '100%',
                                    justifyContent: 'center'
                                },
                                ...Template.left
                            },
                            center: {
                                [onCustomBreakpoint]: {
                                    display: 'none'
                                },
                                [onMobile]: {
                                    display: 'none'
                                },
                                ...Template.center
                            },
                            right: {
                                marginRight: 10,
                                [onCustomBreakpoint]: {
                                    display: 'none'
                                },

                                [onMobile]: {
                                    display: 'none'
                                },
                                ...Template.right
                            }
                        }}
                    ></LCRToOne>
                </SectionFS>
            </>
        );
    }
}
