
import * as React from "react"
import { ClientFooter } from "../client-ui/ClientFooter";
import { LayoutFS, on } from "../locust/composables/Layout";
import { PreloadScreen } from "../locust/composables/PreloadScreen";
import Rsp from "../locust/composables/Rsp";
import HeaderRsp from "../locust/responsive/HeaderRsp";
import Bg from '../assets/images/landing/background-1280.svg'
import Aircraft from '../assets/images/landing/aircraft-1280.svg'
import IconOps from '../assets/images/landing/flight-ops-icon-1280.svg'
import IconSalesServices from '../assets/images/landing/sales-and-service-icon-1280.svg'
import IconTarmac from '../assets/images/landing/tarmac-icon-1280.svg'
import F15 from "../assets/images/landing/f-15c-eagle-1280.svg"

import { css } from "glamor";
import { Stack } from "../locust/composables/Stack";
import SpacerV from "../locust/sections/SpacerV";
import { getFontLato } from "../assets/fonts/FontLato";
import { Link } from "gatsby";
import XY from "../locust/composables/XY";
import { IconFooter } from "./tarmac";
import { Hide2 } from "../locust/composables/Hide";
import { getFontBebasNeue } from "../assets/fonts/FontBebasNeue";
import { getFontMontserrat } from "../assets/fonts/FontMontserrat";
import { getFontMarkoOne } from "../assets/fonts/MarkoOne";
import { getFontRaleway } from "../assets/fonts/Raleway";
import { getFontRoboto } from "../assets/fonts/FontRoboto";
import Scale from "../locust/composables/Scale";

export const onBreak : string = on(670)


const FirstFlightFirstFight = ( props : { style ?: Record<string,any>} ) => {
    return (
      <>
        
        <div {...css({ 
            //left : 'calc(50vw - 150px)',  
            textAlign : 'center',
            marginRight : 0, 
            marginLeft : 'calc(50% - 150px)',
            //marginTop : -50,
            position : 'absolute',
            [on(670)]:{
                marginLeft : 0, 
                //marginTop : -30,
                left : 'calc(50vw - 120px)',  
            },
            [on(350)]:{
              left : 60,
            },
            ...props.style
        })}>
            <SpacerV h={80}/>
            <p {...css({ 
                ...getFontRaleway(), 
                fontSize : 40, 
                textTransform : 'uppercase', 
                fontWeight : 800,
                [on(670)]:{
                  fontSize : 30, 
                },
                [on(450)]:{
                   //fontSize : 20, 
               }
              })}>
                <p>First Flight</p>
                <p>to First Fight</p>
            </p>
            {/*
                <p {...css({ ...getFontRoboto(), fontSize : 26 })}>
                    Wondering Who's the Best
                </p>
            */}
        </div>
        
        </>
    )
}

const Page = () => {
  const [toggle, setToggle] = React.useState(false)
  
  const link : Record<string,any> = css({ display : 'block',textDecoration : 'none', [':hover']:{ textDecoration : 'underline' },[':link']:{ color : 'white'},[':active']:{ color : 'white'},[':visited']:{ color : 'white'}})

  const One = <>
      <p>... "100% worth it and</p>
      <p>so much fun."</p>
  </>

  const Two = <>
      <p>... "Very fun, very realistic,</p>
      <p>very cool!"</p>
  </>

  const Three = <>
      <p>...“The realism is the highest quality</p>
      <p>and so much better than any desktop</p>
      <p>Flight Simulator I have seen. The details</p>
      <p>and effects of the aircraft are remarkable.”</p>
  </>

  const Four = <>
      <p>... “The flight simulator coupled with the VR</p>
      <p>headset is insanely realistic. I did a much more</p>
      <p>expensive flight simulator in California, and this was</p>
      <p>much better at a much more reasonable price.</p>
      <p>Highly recommended!”</p>
  </>

  const FlightOps = <>
      <Link {...link} to={"/flight-ops"}>Simulator</Link>
      <Link {...link} to={"/flight-ops"}>Aircraft</Link>
  </>

  const SalesAndService = <>
      <Link {...link} to={"/sales-and-service"}>Simulator Orders</Link>
      <Link {...link} to={"/sales-and-service"}>Technical Support</Link>
      <Link {...link} to={"/sales-and-service"}>Troubleshooting</Link>
  </>

  const Tarmac = <>
      <Link {...link} to={"/tarmac"}>About Us</Link>
      <Link {...link} to={"/tarmac"}>Pricing</Link>
      <Link {...link} to={"/tarmac"}>Room-scale VR</Link>
      <Link {...link} to={"/tarmac"}>Operating Hours</Link>
      <Link {...link} to={"/tarmac"}>Merchandise</Link>
  </>
  
  const BannerValues = <>
        <p style={{ marginBottom : 7 }}>Flight Simulators & Room-scale VR</p>
        <p>Fun for the Whole Family, Ages 6 to 106 </p>
    </>

  return <>
      <PreloadScreen>
          <LayoutFS min={350}>
            <HeaderRsp breakpoint={750} menu={{ setToggle : setToggle, toggle : toggle }}/>
            
            <div {...css({
                height : 140,
                //[ on(670)]:{ height : 'calc(100vh - 650px)'}

            })}/>
            <FirstFlightFirstFight style={{ 
                    marginTop : -170
                }
            }/>
            <Stack>

              <SectionRsp>
                <div style={{
                     background: `url(${Bg})`,
                     backgroundRepeat : 'no-repeat',
                     backgroundColor : 'white',
                     width : '100%',
                     height : '100%',
                  }}></div>

              </SectionRsp>

              <SectionRsp>
                <div style={{
                     background: `url(${Aircraft})`,
                     backgroundRepeat : 'no-repeat',
                     backgroundSize : '80%',
                     backgroundPosition : 'center',
                     width : '100%',
                     height : '100%',
                     boxShadow: '0 0 8px 8px white inset'
                  }}></div>

              </SectionRsp>

              <SectionRsp>
                  <Hide2 on={670}>
                      <BannerText x={384} y={40}>
                            {BannerValues}
                      </BannerText>
                  </Hide2>
                  <Hide2 b4={670}>
                      <BannerText x={442} y={40} style={{ width : 330, fontSize : 15 }}>
                            {BannerValues}
                      </BannerText>
                  </Hide2>



                  <Hide2 on={1280}>
                      <TestimonialFloat x={-590} y={270}>{One}</TestimonialFloat>
                      <TestimonialFloat x={-590} y={40}>{Two}</TestimonialFloat>
                      <TestimonialFloat x={-590} y={40}>{Three}</TestimonialFloat>
                  </Hide2>

                  <Hide2 b4={1280} on={930}>
                      <TestimonialFloat x={-480} y={270} fontSize={18}>{One}</TestimonialFloat>
                      <TestimonialFloat x={-480} y={40} fontSize={18}>{Two}</TestimonialFloat>
                      <TestimonialFloat x={-480} y={40} fontSize={18}>{Three}</TestimonialFloat>
                  </Hide2>

                  <Hide2 b4={930} on={760}>
                      <TestimonialFloat x={-400} y={370} fontSize={18}>{One}</TestimonialFloat>
                      <TestimonialFloat x={-400} y={40} fontSize={18}>{Two}</TestimonialFloat>
                      <TestimonialFloat x={-400} y={40} fontSize={18}>{Three}</TestimonialFloat>
                  </Hide2>

                  <Hide2 b4={760} on={600}>
                      <TestimonialFloat x={-320} y={480} fontSize={18}>{Two}</TestimonialFloat>
                  </Hide2>

              </SectionRsp>


              <SectionRsp>
                  
                  <Hide2 on={450}>
                      <HudFlag icon={IconOps} x={'min(calc(50% + 180px), 780px)'} y={180}title="Flight Ops">
                          {FlightOps}
                      </HudFlag>
                  </Hide2>
                  <Hide2 b4={450}>
                      <Scale scale={0.8}>
                          <HudFlag icon={IconOps} x={'calc(50% - 80px)'} y={220} title="Flight Ops">
                              {FlightOps}
                          </HudFlag>
                      </Scale>
                  </Hide2>




                  <Hide2 on={450}>
                      <HudFlag icon={IconSalesServices} x={'min(calc(50% + 180px), 780px)'} y={400} title="Sales & Services">
                          {SalesAndService}
                      </HudFlag>
                  </Hide2>
                  <Hide2 b4={450}>
                      <Scale scale={0.8}>
                          <HudFlag icon={IconSalesServices} x={'calc(50% - 80px)'} y={440} title="Sales & Services">
                                {SalesAndService}
                          </HudFlag>
                      </Scale>
                  </Hide2>


                  <Hide2 on={450}>
                      <HudFlag icon={IconTarmac} x={'min(calc(50% + 180px), 780px)'} y={600} title="Tarmac">
                          {Tarmac}
                      </HudFlag>
                  </Hide2>
                  <Hide2 b4={450}>
                      <Scale scale={0.8}>
                          <HudFlag icon={IconTarmac} x={'calc(50% - 80px)'} y={640} title="Tarmac">
                                {Tarmac}
                          </HudFlag>
                      </Scale>
                  </Hide2>

                  

               
              </SectionRsp>

              </Stack>
              <SpacerV h={50}/>
              <IconFooter icon={F15} n={3} size={30}/>
              <SpacerV h={50}/>
            <ClientFooter/>
          </LayoutFS>
      </PreloadScreen>
      
  </>
}

const TestimonialFloat = ( props : { children ?: any, x ?: number, y ?: number, fontSize ?: number  }) => {
  return (
      <div style={{
          marginTop : props.y ? props.y : 0,
          marginLeft : `calc(50% + ${props.x}px)`,
          color : 'black',
          fontSize : props.fontSize ? props.fontSize : 26,
          fontWeight : 700,
          ...getFontLato()
      }}>
          {props.children}
      </div>
  )
}

export const SectionRsp = ( props : { children ?: any, style ?: Record<string,any> }) => {
    return (
        <div {...css({ 
            width : 1280, 
            marginLeft: 'max(calc(50% - 640px) , 0px)',
            height : 790, 
            //background: `url(${Bg})`,
            //backgroundRepeat : 'no-repeat',
            //backgroundColor : 'white',
            overflow : 'hidden',
            //backgroundPosition : 'top center',
            
            [on(1280)]:{
              width : '100vw',
              //marginLeft : `calc(50% - ${1280/2}px)`,
              
            },
            [on(350)]:{
                width : 350,
                //marginLeft : `calc(50% - ${1280/2}px)`,
            },
            ...props.style,
        })}>
            <div {...css({
                
                marginLeft : `calc(50% - ${1280/2}px + 30px)`,
                width : 1280, 
                height : 790, 
                ...props.style,
              
            })}>

                  {props.children}
            </div>
        </div>
    )
}

const BannerText = ( props : { children ?: any, abs ?: boolean, x ?: number, y ?: number, style?: Record<string,any> }) => {
    return (
        <>
            <div {...css({
              position : props.abs ? 'absolute' : 'relative',
              left : props.abs && props.x ? props.x : 0,
              top : props.abs && props.y ? props.y : 0,
              marginLeft : props.x && !props.abs ? props.x : 0,
              marginTop : props.y && !props.abs ? props.y : 0,
              border : '2px solid black',
              width : 450,
              height : 80,
              fontSize : 20,
              fontWeight : 700,
              textTransform : 'uppercase' ,
              color : 'black',
              ...getFontLato(),
              ...props.style
            })}>
              <XY>
                {props.children}
              </XY>
            </div>
        </>
    )
}

const HudFlag = ( props : { icon : any, title : string, children?: any, x ?: number | string, y ?: number | string }) => {
  return (
    <>
    <div {...css({ 
                position : 'absolute',
                width : 400, 
                marginTop : props.y ? props.y : 0,
                marginLeft: props.x ? props.x : 0,
                height : 150, 
                background: `url(${props.icon})`,
                backgroundRepeat : 'no-repeat',
                backgroundPosition : 'top',
                //[on(1280)]:{ width : '100%', }
              })}>
                <div {...css({
                  height : 30,
                  
                  position: 'absolute',
                  textAlign : 'right',
                  
                  //backgroundColor : 'lime',
                  width : 300, 
                  
                  textTransform : 'uppercase' ,
                  color : 'white',
                  ...getFontLato(),
                  fontSize : 30,
                    marginTop : -33,
                    marginLeft : 100
                  
                })}>{props.title}</div>

                <div {...css({
                  height : 100,
                  marginTop : 4,
                  position: 'absolute',
                  textAlign : 'right',
                  marginLeft : 140,
                  //backgroundColor : 'lime',
                  width : 260, 
                  fontSize : 20,
                  textTransform : 'uppercase' ,
                  color : 'white',
                  ...getFontLato()
                })}>{props.children}</div>

              </div>
    </>
  )
}
export default Page;