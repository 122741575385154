import React, { Component } from "react"

export class PreloadScreen extends Component<{
    children ?: any 
}>{
    state={
        ready : false
    }
    componentDidMount = () => {

        this.setState({ ready : true })
    }
    render(){

        return<> { this.state.ready && <>{this.props.children}</>}</>
        
    }
}