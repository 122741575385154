import { motion } from 'framer-motion'
import { css } from 'glamor';
import React from 'react'
import { getConfigDefaults, GlobalConfig } from './Layout';
import { getChildren } from './Shared';

type Transform = 'top' | 'top left' | 'top right' | 'bottom' | 'bottom left' | 'bottom-right' | 'center'

interface ScaleModel {
    width : number, value : number, origin ?: Transform
}

const Scale = ( props : { children ?: any, scale : number, origin ?: Transform  }) => {
    
    return (
        <>
            <motion.div                 
                initial={{ 
                    scale : props.scale,
                    transformOrigin: props.origin ? props.origin : 'left top',
                }}
            >   
                {props.children}
            </motion.div>
        </>
    )
}
export default Scale;



export const ScaleRsp = ( props : { children ?: any, 
    scales : {width : number, value : number, origin ?: Transform}[]
}) => {
    const globals : GlobalConfig = getConfigDefaults()

    return (
        <>  
            
            { props.scales.map(( scale : ScaleModel, index : number  )=>{

                const hasNext : boolean = index < props.scales.length - 1
                const hideOn = hasNext ? {
                    [ globals.onMedia( props.scales[index + 1].width )] : {
                        display : 'none',
                    }
                } : {}
                const showOn = index > 0 ? {
                    [ globals.onMedia( scale.width )] : {
                        display : 'block',
                    }
                } : {
                    display : 'block'
                }
                return <div {...css({
                    display : 'none',
                    ...showOn,
                    ...hideOn
                })}>
                    <Scale scale={scale.value} origin={scale.origin}>
                        {props.children}
                    </Scale>
                </div>
            })}

        </>
    )

}


export const ScaleRspExample = () => {
    return (
        <ScaleRsp scales={[
            
            { width : 900, value : 1.0, origin : 'top'},
            { width : 800, value : 0.6, origin : 'top'},
            { width : 700, value : 0.4, origin : 'top'},
            { width : 600, value : 0.2, origin : 'top'},

          ]}>
            <div style={{ width : 800, height : 800, backgroundColor : 'lime'}}></div>
          </ScaleRsp>
    )
}