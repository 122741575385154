import { css } from 'glamor'
import React from 'react'
import { getConfigDefaults, GlobalConfig } from "./Layout"
import { getChildren, injectProps } from './Shared'


export default( props : { 
    children : any,
    on : number,
    globals?: GlobalConfig
}) => {

    const children = getChildren(props.children);
    const childrenWithParentProps = injectProps(children, {
        globals: props.globals
    });
    const onMediaquery: string = `@media (max-width: ${props.on}px)`;
    return (
        <div {...css({
          display : 'block',
          [onMediaquery] : {
              display : 'none'
          }
        })}>{ childrenWithParentProps }</div>
      );
}

export const Hide2 = ( props : { children ?: any, on ?: number, b4?: number }) => {
    const on = ( value : number ) => getConfigDefaults().onMedia( value )

    const params = {
        ...( props.b4 ? {
            display : 'none'
        } : {}),
        ...( props.b4 ? {
            [on( props.b4 )]:{ 
                display : 'block'
            }
        } : {}),
        ...( props.on ? {
            [on( props.on )]:{ 
                display : 'none'
            } 
        } : {})
    }

    return (<>
        <div {...css(params)}>
            {props.children}
        </div>
    </>)
   
}