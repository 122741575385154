import { Link } from 'gatsby';
import { css } from 'glamor';
import React, { Component } from 'react';
import { getConfigDefaults, onMedia } from '../composables/Layout';
import X from '../composables/X';
import XY from '../composables/XY';
import { LCRToColTemplate } from '../sections/SectionLCRToCol';
import SpacerV from '../sections/SpacerV';
import { IconSplitTitle, IconSplitTitleTemplate } from '../components/IconSplitTitle';
import { FACEBOOK_BLACK } from '../../assets/colors/Colors';

export interface LinkModel {
    url : string,
    value : string,
    icon ?: any,
}

const Footer = ( props : {
    siteMap ?: LinkModel[]
    contacts ?: LinkModel[]
    social ?: LinkModel[]
}) =>  {
        
        
        return (
            <div {...containerStyle}>  
                
                <div style={{ height : 'auto', backgroundColor : FACEBOOK_BLACK, display : 'block', paddingLeft : 40 }}>
                    {/*
                        <SpacerV h={30}/> 
                        <X> 
                            <IconSplitTitle
                                left='LOCUST'
                                right='LABS'
                                center={<></>}
                                template={getLogoSplitTitleTemplate()}
                            />
                            
                        </X>
                    */}
                    <SpacerV h={30}/> 
                    
                    { props.siteMap && 
                        <div {...footerThird}>
                            
                                <div {...linkSection}>
                                    <SpacerV h={20}/>
                                    <p style={{ color : 'white'}}>Site Map</p>
                                    {
                                        props.siteMap.map(( link : LinkModel )=>{
                                            return <Link {...linkStyle} to={link.url}>{link.value}</Link>

                                        })
                                    }
                                </div>
                            
                        </div>
                    }
                    { props.contacts && 
                        <div {...footerThird}>
                            
                                <div {...linkSection}>
                                    <SpacerV h={20}/>
                                    <p style={{ color : 'white'}}>Contact Us</p>
                                    {
                                        props.contacts.map(( link : LinkModel )=>{
                                            return <Link {...linkStyle} to={link.url}>{link.value}</Link>

                                        })
                                    }
                                </div>
                            
                        </div>
                    }
                    { props.social && 
                        <div {...footerThird}>
                            
                                <div {...linkSection}>
                                    <SpacerV h={20}/>
                                    <p style={{ color : 'white'}}>Social Media</p>
                                    {
                                        props.social.map(( link : LinkModel )=>{
                                            return <Link {...linkStyle} to={link.url}>{link.value}</Link>

                                        })
                                    }
                                </div>
                            
                        </div>
                    }
                    
                    <SpacerV h={30}/>

                </div>
            </div>
        );
}




export const FooterFS960 = ( props : {
    siteMap ?: LinkModel[]
    contacts ?: LinkModel[]
    social ?: LinkModel[]
}) => {
    /*
        Two version of the footer are required here due to the way the Layout module is set up. 
        The problem is essentially as following: 
            
            (1) if the footer is inside the Layout module then with will not not expand to the 
            full width when the page width > 960. 
            (2) if the footer is not inside the Layout module then when it is less than the full
            page width it will have a decoubled horizontal scroll. 

            The solution is to put it inside the Layout module and give it an absolute positin 
            when the screen is large enough not to require a scroll then on the smallest screen 
            size, we set its positioning to relative so that it will be responsive.


    */

    const globals = getConfigDefaults()
    const footerProps : any = {
        siteMap : props.siteMap,
        contacts : props.contacts,
        social : props.social
    }

    return (
        <>
            
                <div {...css({ 

                        width : '100%' ,
                        height : 'auto',
                        display : 'block',
                        backgroundColor : 'red',
                        
                        
                      
                  })}>
                    <Footer {...footerProps}/>
                </div>
        
        </>
        
    )
}
export default FooterFS960;



export const getLogoSplitTitleTemplate = () => {
    const template: IconSplitTitleTemplate = {
        height: {
            height: 100
        },
        wrapper: {
            width: 260,
            color: 'white'
        },
        container: {
            width: 300
        },
        left: {
            ...Typeface.poppinsCapital,
            marginTop: 44,
            marginRight: 5
        },
        center: {},
        right: {
            ...Typeface.poppinsCapital,
            marginTop: 44,
            marginLeft: 10
        }
    };
    return template;
};

export enum ScreenSizes {
    MINIMUM = 320,
    MOBILE = 420,
    TABLET = 680,
    BREAKPOINT = 960
}

//export const onBreakpoint: string = `@media (max-width: ${ScreenSizes.BREAKPOINT}px)`;
//export const onMinimum: string = `@media (max-width: ${ScreenSizes.MINIMUM}px)`;
//export const onMobile: string = `@media (max-width: ${ScreenSizes.MOBILE}px)`;
export const onTablet: string = `@media (max-width: ${ScreenSizes.TABLET}px)`;

export const Typeface: any = {
    largeBebas: {
        'font-family': 'Bebas Neue, sans-serif',
        'font-feature-settings': 'liga',
        'font-weight': 400,
        'text-transform': 'uppercase'
    },
    poppins: {
        'font-family': 'Poppins, sans-serif',
        'font-feature-settings': 'liga'
    },
    poppinsCapital : {
        'font-family': 'Poppins, sans-serif',
        'font-feature-settings': 'liga',
        'text-transform': 'uppercase',
        fontSize: 16,
        fontWeight: 600
    },
    poppinsThin : {
        'font-family': 'Poppins, sans-serif',
        'font-feature-settings': 'liga',
        'text-transform': 'uppercase',
        fontSize: 13,
        letterSpacing: 5.0
    },
    poppinsSmall : {
        'font-family': 'Poppins, sans-serif',
        'font-feature-settings': 'liga',
        fontSize: 7,
        fontWeight: 600,
        lineHeight: '15px',
        verticalAlign: 'top'
    }
}

const containerStyle = css({
    overflow : 'hidden',
    height : 'auto',
    //[onMedia(320)] : {
        //backgroundColor : 'red',
      //  width : 320,
    //}
})

const footerThird = css({
    width : `${100/3}%`, 
    height : '100%', 
    display : 'inline-block',
    verticalAlign : 'top',
    [onMedia(450)] : {
        //backgroundColor : 'red',
        marginLeft : 'calc(50vw - 120px)',
        paddingBottom : 20, 
        paddingLeft : 20, 
        height : 'auto', 
        display : 'block',
        width : 300
    },
})

const linkSection = css({
    ...Typeface.poppins, 
    fontWeight : 600, 
    fontSize : 20, 
    width : 150, 
    //backgroundColor : ''
})

const linkStyle = css({
    ...Typeface.poppins, 
    fontWeight : 400,
    fontSize : 14, 
    marginTop : 4,
    display : 'block',
    textTransform:'capitalize',
    [':link'] : { 
        color: 'white',
        textDecoration : 'none',
        [':hover']: {
            textDecoration: 'underline'
        }
    },
    [':visited']: { color: 'white' },
    
})
