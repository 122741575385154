import React, {Component} from "react"

import { css, style } from "glamor"
import { getConfigDefaults, on } from "../composables/Layout"
import { Header } from "../components/Header"
import X from "../composables/X"
import { getColors, ifProp, onHover } from "../composables/Shared"
import XY from "../composables/XY"
import { getFontBebasNeue } from "../../assets/fonts/FontBebasNeue"
import { getFontPoppins } from "../../assets/fonts/FontPoppins"
import { getFontMontserrat } from "../../assets/fonts/FontMontserrat"
import { UniversalTypographySetPrototype } from "../typography/Typography"
import { LocustColors } from "../../client-ui/LocustLabsTypography"
import Y from "../composables/Y"
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import { motion } from 'framer-motion'
import SpacerV from "../sections/SpacerV"
import { LinkTemplate, LinkType } from "../composables/Link"
import Logo from '../../assets/images/landing/flight-line-vr-logo.png'
import LogoHD from '../../assets/images/landing/flight-line-vr-logo-hd.jpg'
import { Link } from "gatsby"
import { Hide2 } from "../composables/Hide"


// https://styled-icons.dev/

export const FlightLineLogo = () => {
    const link : Record<string,any> = css({ display : 'block',textDecoration : 'none', [':hover']:{ textDecoration : 'underline' },[':visited']:{ color : 'white'}})
    const scale : number = 2.5
    
    return (
        <Link {...link} to={"/"}>
            <div {...style({ 
                background: `url(${LogoHD})`, 
                width : 350, 
                height : 150, 
                marginTop : 20, 
                //marginLeft : marginLeft, 
                marginBottom : 100,
                backgroundRepeat : 'no-repeat',
                backgroundSize : '100%',
                [on(670)] : {
                    marginLeft : -80,
                }
            })}/>
        </Link>
    )
}

export default class extends Component<{
    breakpoint : number
    menu?:{
        toggle : boolean,
        setToggle : any
    }
}>{

    
    render(){
        
        const globalDefaults = getConfigDefaults()
        const onBreak : string = globalDefaults.onMedia(this.props.breakpoint)
        const toggle : boolean = ifProp(this.props.menu?.toggle, false )
        const setToggle : () => void = ifProp(this.props.menu?.setToggle, ()=>{})
        
        const callText : string = '(208) 918-3427'
        const callLink : string = 'tel:208-918-3427'
        
        const LinkStyle : Record<string,any> = {
            [':link'] : { color : 'black'}, 
            [':visited'] : { color : 'black'}, 
            [':hover']:{ textDecoration: 'underline'},
            textDecoration : 'none' 
        }

        const TextStyle : Record<string,any> = {
            textAlign : 'right', 
            width : 250, 
            ...getFontPoppins(), 
            fontWeight : 800, 
            textTransform : 'uppercase',
            [on(670)] : {
                textAlign : 'center', 
            }
        }

        return(<>
            
            <div {...css({ position : 'absolute', left : 'calc(100% - 270px)', top : 20, 
                [on(670)]:{ 
                    left : 'calc(50vw - 120px)',            
                },
                [on(350)]:{ 
                    left : 50,            
                }
            })}>
                <div {...css(TextStyle)}>
                        <Link {...css(LinkStyle)} to={ '/' }>{'Home'}</Link>
                </div>
                <div {...css(TextStyle)}>
                        Call Us: <Link {...css(LinkStyle)} to={ callLink }>{callText}</Link>
                </div>
                <div {...css(TextStyle)}>
                    Our Location: <Link {...css(LinkStyle)} to={ 'https://goo.gl/maps/7ZonL9kmFix4FAwg7' }>{'Google Maps'}</Link>
                </div>
            </div>
            
            <Hide2 b4={670}><SpacerV h={100}/></Hide2>
            

            <div {...css({ 
                position : 'absolute', 
                left : '20px', 
                [ on(670) ]:{ left : 'calc(50vw - 100px)'},
                [on(350)]:{ 
                    left : 80,            
                }
               
            })}>
                <div style={{ display : 'inline-block', height : 100, width : 200}}>
                    <FlightLineLogo/>
                </div>
               
            </div>
            <SpacerV h={120}/>
            
            
        
        
        </>)
        
    }
}


export const DropDownMenuHamburger = ( props : { setToggle : () => void }) => {
    return (
        <div {...css({ 
            cursor : 'pointer', 
            width : 40, 
            height :40, 
            display : 'inline-block',
            borderRadius : 6,
            padding : 4,
            [ onHover] : {
                backgroundColor : 'rgba(0,0,0,0.05)'
            }
        })} >  
            <XY>
            <MenuIcon 
                style={{ width : '100%', height : '100%'}}
                onClick={()=>{ 
                    props.setToggle()
                }}
            />
            </XY>
        
        </div>
    )
}
export const DropDownMenuArea = ( props : { menuToggle : boolean, links ?: { value : string, href : string }[] }) => {

    function goTo ( URL : string ) {
        setTimeout( function() { window.location.href = URL }, 100 );
    }

    return (
        <>
            { props.menuToggle && 
            <div style={{ position : 'absolute',  top : 70, width : '100vw' }}>
                <X>
                    
            <motion.div   
                initial={{ scale : 0 }}
                animate={{ scale : 1 }}
                //transition={{ duration: 0.7 }}
            >   
                        <div 
                            style={{ 
                                borderBottomLeftRadius: 4, 
                                borderBottomRightRadius: 4, 
                                width : 250, 
                                border: '1px solid rgba(0,0,0,0.3)', 
                                borderTop: '0px solid rgba(0,0,0,1)', 
                                left: 'calc(100% - 250px)', 
                                height : 'auto', 
                                backgroundColor : 'white'
                            }}>  

                                { props.links && props.links.map(( link )=>{

                                    return (
                                        <Button onClick={()=>{ goTo(link.href) }}
                                        style={{ height : 60, color : 'black' }}
                                        fullWidth
                                        variant="text">
                                            {link.value}
                                        </Button>  
                                    )

                                })}
                        </div>
                    
                </motion.div>
                </X>
                </div>
                }
                
                
            
        </>
    )
}




export const getExampleTypographySet = ( onMobile: string ) => {
    const defaultColor : string = '#122848'
    const setTasty : UniversalTypographySetPrototype = {
        title : {
            ...getFontBebasNeue(),
            fontSize : 132,
            color : defaultColor,
            height: 160,
            [onMobile] : {
                fontSize : 'max(30vw, 95px)',
            }
        },
        text : {
            ...getFontBebasNeue(),
            fontSize : 24,
            color : defaultColor
        },
        textLight : {
            ...getFontBebasNeue(),
            fontSize : 24,
            color : defaultColor,
            opacity : 0.5
        },
        subtitle : {
            ...getFontPoppins(),
            fontSize : 14,
            fontWeight: 500, 
            maxWidth : 600,
            textAlign : 'center',
            color : defaultColor
        },
        fineprint : {
            ...getFontPoppins(),
            color : defaultColor,
            fontSize : 12,
            fontWeight: 600, 
            fontStyle: 'italic'
        },
        table:{
            ...getFontMontserrat(),
            color : defaultColor,
            fontSize : 13,
            fontWeight: 500
        } 
    }

    return setTasty
}



export const getExampleQuickNavLinkData = () => {

    return [
        {href : "/", payload : 'Home'},
        {href : "/design", payload : 'Design'},
        {href : "/writing", payload : 'Writing' },
        {href : "/growth", payload : 'Growth'},
        {href : "/hosting", payload : 'Hosting'},
        {href : 'mailto:support@locustlabs.com', payload : 'Contact Us'}
        
    ]
}



export const getExampleClientContent = () => {
    const clientContent = {
        pages : {
            landing : {
                section : {
                    products : {
                        title : "Products",
                        subtitle : "Steaks Roasts Hamburger & More",
                        steak : {
                            title : "The flavor of Tasty Beef qualifies for Prime or Top Choice consideration. Tender and succulent with that great Angus flavor.",
                            fineprint : "Generally, if you order a Half Beef with a hanging-weight of 400 lb, you will get about 12 ribeye, 14 t-bones, and 6 sirloin steaks DEPENDING ON CUT INSTRUCTIONS",
                            value1 : 'Ribeye',
                            value2 : 'T-Bone',
                            value3 : 'Sirloin',
                        },
                        hamburger : {
                            title : "Angus ground beef truly makes the best burgers and cooking them on the grill makes them even better!",
                            subtitle1 : "WHOLE, HALF, QUARTER BEEF ORDER",
                            value1 : "1.5 or 2 lb pkgs",
                            subtitle2 : "HAMBURGER ONLY ($200 MIN ORDER)",
                            value2A : "Optional BULK ORDER (2 lb packaging only)",
                            value2B : "Or 6-PACK ORDER (6 patties per pack at your choice of 1/3 lb or 1/4 lb)",
                            fineprint: "1.5 and 2 lb packaging. 1/4 and 1/3 lb patties are available in a 6-pack order."
                        },
                        roasts : {
                            title : "What would a Sunday dinner be if not for roasts! The finishing process of cracked-corn and alfalfa infuses Prime/Top Choice marbling. Sear your roast and drop it into a crock-pot. You won't even need a knife.",
                            value1 : "Chuck",
                            value2 : "Round",
                            value3 : "Arm",
                            value4 : "Rump",
                            fineprint: "Generally, if you order a Half Beef with a hanging weight of 400 lb, you will get about 30 lb of a variety of roast cuts"
                        },
                        ribsandmore : {
                            title : "You slow cook these babies and you will experience fall-off-the-bone tenderness. These ribs taste as good with a dry rub as they do smothered in your favorite BBQ sauce.",
                            value1 : "Short Ribs",
                            subtitle1 : "And More, Including...",
                            value2 : "Brisket",
                            value3 : "Tri-tip",
                            value4 : "Flank Steak",
                            value5 : "Stew Meat",
                            fineprint: "Generally, if you order a Half Beef with a hanging weight of 400 lb you will get about 10 lb or about 30 ribs"
                        }
                    },
                    orders : {
                        title : "Orders",
                        subtitle : "Orders for Whole, Half or Quarter Beef are priced based on hanging weight. Deposit Required: Whole Beef, $500; Half Beef, $300; Quarter Beef, $250",
                        menu1 : {
                            title : "WHOLE OR HALF BEEF",
                            subtitle : '$3.50/lb',
                            value1 : 'Does not include processing fees and does NOT include delivery. For this option, you pay the butcher directly and pick-up yourself.',
                            fineprint : 'Note that you need to bring an appropriate number of coolers or boxes to pick-up your order.'
                        },
                        menu2 : {
                            title : "WHOLE OR HALF BEEF",
                            subtitle : '$4.50/lb',
                            value1 : 'Includes processing and packaging fees and delivery to your door.',
                        },
                        menu3 : {
                            title : 'QUARTER BEEF',
                            subtitle : "$5.25/lb",
                            value1 : 'Includes processing and packaging fees and delivery to your door.'
                        },
                        menu4 : {
                            title : 'HAMBURGER',
                            subtitle1 : "$9.00/pkg",
                            value1 : 'Bulk packaging: 2 lb each',
                            subtitle2 : "$7.50/pkg",
                            value2: '6-pack, 1/4 lb patties',
                            subtitle3 : "$10.00/pkg",
                            value3: '6-pack, 1/3 lb patties',
                            fineprint : 'Minimum order $200'
                        }
                    },
                    about : {
                        title : "About Us",
                        subtitle : "Want to know more about our process?",
                        value1 : "Finished on cracked corn and alfalfa",
                        value2 : "No hormones and no antibiotics",
                        value3 : "We use only USDA inspected facilities",
                        value4 : "Hamburger only orders ($200 minimum) are available",
                        value5 : "Vacuum Packed",
                        value6 : "Delivery to Treasure Valley and surrounding areas"
                    },
                    faq : {
                        title : 'FAQ',
                        subtitle : "Still have unanswered questions?",
                        value1 : [
                          
                            {
                                title: 'What does it mean to finish with cracked-corn and alfalfa?',
                                text: 'The final stage of feeding is known as finishing. This is when the cow gains its final weight and the meat takes on the marbling so critical to flavor. We use cracked corn to add weight and alfalfa to sweeten the taste.'
                            },
                            {
                                title: 'How long does the finishing cycle take?',
                                text: 'Generally, the finishing cycle can be anywhere from 30 to 90 days with actual length of the cycle depending on customer and processing schedules. We guarantee at least a 30 day finishing cycle.'
                            },
                            {
                                title: 'How much time will it take between placement and receipt of the order?',
                                text: `The lead time on the order can be anywhere from immediately to 2 months out depending on the processing cycle. We generally process our beef on a monthly basis so your order will typically be available within 30 days. So, if we can't get your order processed in the 'current' cycle, you won't have long to wait.`
                            },
                            {   
                                title : "What are the different 'weight measurements' used during the processing cycle?",
                                text : 'There are three different weight measurements we use: on-the-hoof, hanging, and take-home. The weight of the live animal is referred to as the on-the-hoof weight. The hanging weight is the measurement after it has been field dressed but before it has been aged, cut, packaged, and frozen. The take home weight is also known as the package weight and is the final weight of the order you receive. It is important to understand that the take home weight is approximately 60-65% of the hanging weight because of the parts not included in your order (e.g., bones).'
                            },
                            {
                                title: `Why do you use the hanging weight as the basis for your orders?`,
                                text: `We use the hanging weight measurement because we are not selling indivudual retail cuts but instead are selling by the quarter, half, or whole.`
                            },
                            {
                                title: `Who does your butchering and how is the product packaged?`,
                                text: `We use more than one butcher to ensure we meet our customer commitments. We verify that all our butchers are USDA inspected so as to ensure health and quality standards are rigidly maintained. We also will deliver to a butcher of your choice, if you prefer.`
                            },
                            {
                                title: `Do you accommodate custom cut requests?`,
                                text: `Absolutely! We work with each customer to fill-out a 'Cut Sheet' with the details of their order.`
                            },
                        ]
                    },
                    reviews : {
                        title : "Reviews",
                        subtitle: "Check out what our customers are saying about us.",
                        value1 : [
                            {
                                name: 'Annette and Ray N.',
                                subtitle: 'Mountain Home, Idaho',
                                rating: 5.0,
                                text: (
                                    <>
                                        With the price of retail beef these days, buying from Tasty Beef gets you premium quality Angus at just about the same price as regular retail hamburger. THERE IS NO COMPARISON!!! THERE IS NO BETTER DEAL THAN TASTY BEEF!
                                    </>
                                )
                            },
                            {
                                name: 'Kenny D.',
                                subtitle: 'Nampa, Idaho',
                                rating: 5.0,
                                text: (
                                    <>
                                        We are on our second side of beef from Brent and we have not been disappointed. This beef is delicious!
                                    </>
                                )
                            },
                            {
                                name: 'Pam B.',
                                subtitle: 'Caldwell, Idaho',
                                rating: 4.5,
                                text: (
                                    <>
                                        I loved the hamburger! We ran out of your beef and I had to buy regular hamburger from a local retailer and it’s gross. Can’t wait to pick-up our next order!
                                    </>
                                )
                            },
                            {
                                name: 'Michelle',
                                subtitle: 'Eagle, Idaho',
                                rating: 5.0,
                                text: (
                                    <>
                                        Brent, just a quick note to let you know that you have a customer for life. The beef is incredible!! We could not be happier with our purchase. THANK YOU!!!
                                    </>
                                )
                            },
                            {
                                name: 'Krista D.',
                                subtitle: 'Meridian, Idaho',
                                rating: 5.0,
                                text: (
                                    <>
                                        
                                    The steaks were huge and tender. I’m in awe. Thank you so much! I had planned on sharing this side, but now I’m thinking I’m going to hoard this whole sucker.
                                    </>
                                )
                            } ,
                            {
                                name: 'Ethan H.',
                                subtitle: 'Twin Falls, Idaho',
                                rating: 5.0,
                                text: (
                                    <>
                                        
                                        We had the short ribs the other night and they were great. The tri-tip I had this morning with eggs is even better. I am on the carnivore diet, and this meat has been a blessing. I’m down 5lbs since Monday.
                                    </>
                                )
                            },
                            {
                                name: 'Del B.',
                                subtitle: 'Idaho Falls, Idaho',
                                rating: 5.0,
                                text: (
                                    <>
                                        Had hamburger last night and BBQ sirloin steak tonight. OUTSTANDING!! Wife is very happy. Thank you!! You are our beef guy now. LOL
                                    </>
                                )
                            },
                            {
                                name: 'Michi',
                                subtitle: 'Middleton, Idaho',
                                rating: 5.0,
                                text: (
                                    <>
                                        Well, we had the steak tonight and it was great. What a flavor! As good as you’ll find in any steakhouse, but nowhere near the price. We will definitely do this again when we are getting low on beef which will be a bit … lol … Thanks again.
                                    </>
                                )
                            },
                            {
                                name: 'Duane V.',
                                subtitle: 'Nampa, Idaho',
                                rating: 5.0,
                                text: (
                                    <>
                                        We love the ground beef and we love supporting local ranchers/farmers.
                                    </>
                                )
                            },
                            {
                                name: 'Andrew C.',
                                subtitle: 'Ontario, Oregon',
                                rating: 5.0,
                                text: (
                                    <>
                                        This beef is absolutely great.
                                    </>
                                )
                            },
                            {
                                name: 'Victor C.',
                                subtitle: 'Meridian, Idaho',
                                rating: 5.0,
                                text: (
                                    <>
                                        We made burgers and they are amazing. Will definitely be calling you for more soon. Thank you.
                                    </>
                                )
                            },
                            {
                                name: 'Brad F.',
                                subtitle: 'Twin Falls, Idaho',
                                rating: 5.0,
                                text: (
                                    <>
                                        The meat is great! Might need to buy more. And that butcher you use is top notch!!!
                                    </>
                                )
                            }
                        ]
                    },
                    connect : {
                        title : 'Connect',
                        subtitle : "Our preferred method of contact is text. If you have any further questions, lets get connected.",
                        value1: "12087133124",
                        value1B: "208-713-3124",
                        value1C: "Hi Brent, I have some questions I would like to ask about Tasty Beef...",
                        value2: "brent@tastybeefidaho.com",
                        value3: "Boise, Nampa, Meridian, Twin Falls, Caldwell, Ontario And More!",
                        value4: "https://m.facebook.com/tastybeefidaho/"
                    }
                }
            },
        }
    }
    return clientContent
} 

export const getLinkTemplateLocustLabs =() => {
    const tastyTypography = getExampleTypographySet(getConfigDefaults().onMedia(getConfigDefaults().MOBILE))
    const defaultColor : string = '#122848'
    const highlightColor : string = '#D72328'
    const template : LinkTemplate = {
        onDefault: {
            ...getFontPoppins(),
            color : LocustColors('blue'),
            fontSize : 16,
            fontWeight : 600,    
            paddingLeft : 20, 
            paddingRight : 20,
            transition : '200ms'
        },
        onVisited: {
            
        },
        onHover: {
            //color: highlightColor,
            
            fontSize : 17,
            transition : '200ms'
        },
        align : true 
    }
    return template
}

{/*
export const getLinkLocustLabs =( href : string, payload?: any ) => {
  return <Link href={href} template={getLinkTemplateLocustLabs()}>
      <X style={{ width : 100 }}><Y h={70}>{payload}</Y></X>
  </Link>
}
*/}

// turn this into class [UPDATE]
export function HeaderSimpleLinks( props ?: {
        links : {
        href : string,
        payload ?: any,
        type?: LinkType
   }[]
}){
  return<>
      <Header

          center={
              <X>
                  {props?.links.map(( item : any )=>{
                      //return <>{getLinkLocustLabs(item.href, item.payload)}</>
                  })}
              </X>
          }
          template={{
              wrapper : {
                  backgroundColor : 'white',
              },
              left : { display : 'none' },
              center : { width : 600 },
              right : { display : 'none' },
          }}
      />
  </>
}