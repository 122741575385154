
import { css } from 'glamor';
import React, {Component, ReactNode} from 'react'
import { CSS, getChildren, injectProps } from './Shared';

export const Stack = ( props : { children?: any, style?: CSS }) => {
    const children: any[] = getChildren( props.children );
    const encapsulated : any[] = children.map(( item : ReactNode )=>{
        return <div style={{
            gridColumnStart: 1,
            gridRowStart: 1,
        }}>{item}</div>
    })
    return <div style={{ display : 'grid'}}>{encapsulated}</div>
}

/*
export class StackII extends Component<{
    style?: CSS
}> {
    render(){
        const children: any[] = getChildren(this.props.children);
        const childrenII : any[] = children.map(( item : ReactNode )=>{
            return <div style={{
                gridColumnStart: 1,
                gridRowStart: 1,
            }}>{item}</div>
        })
        return <div style={{ display : 'grid'}}>{childrenII}</div>
    }
}


export default class StackPrevious extends Component<{
    children ?: any, 
    style?: CSS
}> {
    render(){
        const children: any = getChildren(this.props.children);

        const childrenWithParentProps = injectProps(children, { 
            style : {
                gridArea : '1 / 1 / 2 / 2',                
            }
        });

        return <div {...css({
            ...this.props.style,
            display: 'grid'
        })}>
           {childrenWithParentProps.map(( item : any )=>{
                return <div {...css({
                    gridArea : '1 / 1 / 2 / 2'
                })}>{item}</div>
            })}
        </div>
    }
}
*/