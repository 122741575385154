import React, { Component, ReactNode } from 'react';
import { css } from 'glamor';
import { onMedia } from '../composables/Layout';
import Y from '../composables/Y';
import XY from '../composables/XY';
import { Ref } from '../composables/Shared';

interface LCRToOneStyles {
    wrapper: Ref;
    left: Ref;
    center: Ref;
    right: Ref;
}

export default class LCRToOne extends Component<{
    breakpoint: number;
    left?: ReactNode;
    center?: ReactNode;
    right?: ReactNode;
    template?: LCRToOneStyles;
    show?: boolean;
}> {
    state = {};

    render() {
        const onPropBreakpoint: string = onMedia(this.props.breakpoint);

        const show: boolean = this.props.show ? this.props.show : false;

        const leftStyle: Record<string, any> = {
            //float: 'left',
            ...this.props.template?.left
        };

        const centerStyle: Record<string, any> = {
            //float: 'clear',
            ...this.props.template?.center
        };

        const rightStyle: Record<string, any> = {
            //float: 'right',
            ...this.props.template?.right
        };

        return (
            <Y
                style={{
                    flexDirection: 'row',
                    [onPropBreakpoint]: {
                        flexDirection: 'column'
                    },
                    ...this.props.template?.wrapper
                }}
            >
                <XY
                    align='left'
                    wrapper={{
                        width: `${100 / 3}%`,
                        backgroundColor: show ? 'red' : 'transparent',

                        [onPropBreakpoint]: {
                            width: '100%',
                            height: 'auto'
                        },
                        ...leftStyle
                    }}
                    style={{
                        [onPropBreakpoint]: {
                            width: '100%',
                            backgroundColor: show ? 'yellow' : 'transparent',
                            height: 'auto'
                        }
                    }}
                >
                    {this.props.left}
                </XY>

                <XY
                    align='center'
                    wrapper={{
                        width: `${100 / 3}%`,
                        backgroundColor: show ? 'blue' : 'transparent',
                        [onPropBreakpoint]: {
                            width: '100%',
                            height: 'auto'
                        },
                        ...centerStyle
                    }}
                    style={{
                        [onPropBreakpoint]: {
                            width: '100%',
                            backgroundColor: show ? 'orange' : 'transparent',
                            height: 'auto'
                        }
                    }}
                >
                    {this.props.center}
                </XY>

                <XY
                    align='right'
                    wrapper={{
                        width: `${100 / 3}%`,
                        backgroundColor: show ? 'purple' : 'transparent',
                        [onPropBreakpoint]: {
                            width: '100%',
                            height: 'auto'
                        },
                        ...rightStyle
                    }}
                    style={{
                        [onPropBreakpoint]: {
                            width: '100%',
                            backgroundColor: show ? 'pink' : 'transparent',
                            height: 'auto'
                        }
                    }}
                >
                    {this.props.right}
                </XY>
            </Y>
        );
    }
}
